var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"900px","persistent":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"error"},model:{value:(_vm.errorAlert),callback:function ($$v) {_vm.errorAlert=$$v},expression:"errorAlert"}},[_vm._v(" "+_vm._s(_vm.errorAlertText)+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.categories,"auto-select-first":"","item-text":_vm.getRawMaterialText,"item-value":"item"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_c('p',{staticClass:"profile-p"},[_vm._v(" "+_vm._s(item)+" ")])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_c('p',{staticClass:"profile-p"},[_vm._v(" "+_vm._s(item)+" ")])])]}}]),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},[_c('template',{slot:"label"},[_vm._v(" Category ")])],2),_c('v-autocomplete',{attrs:{"items":_vm.computedInventoryitems,"item-text":_vm.getRawMaterialText,"item-value":"item","rules":[
                    function (v) { return !!v || 'Raw Material Consumption is required'; } ],"required":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_c('p',{staticClass:"profile-p"},[_vm._v(" "+_vm._s(item.material_id)+": "+_vm._s(item.name)+" ")])])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [(item.address)?_c('span',{staticClass:"black--text"},[_c('p',{staticClass:"profile-p"},[_vm._v(" "+_vm._s(item.material_id)+": "+_vm._s(item.name)+" ")])]):_c('span',{staticClass:"black--text"},[_c('p',{staticClass:"profile-p"},[_vm._v(" "+_vm._s(item.material_id)+": "+_vm._s(item.name)+" ")])])]}}]),model:{value:(_vm.consumption),callback:function ($$v) {_vm.consumption=$$v},expression:"consumption"}},[_c('template',{slot:"label"},[_vm._v(" Raw Material"),_c('RedAsterisk')],1)],2),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Quantity supplied is required'; }],"type":"number"},model:{value:(_vm.quantity_supplied),callback:function ($$v) {_vm.quantity_supplied=$$v},expression:"quantity_supplied"}},[_c('template',{slot:"label"},[_vm._v(" Quantity supplied"),_c('RedAsterisk')],1)],2),_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Quantity missing is required'; }],"type":"number"},model:{value:(_vm.quantity_missed),callback:function ($$v) {_vm.quantity_missed=$$v},expression:"quantity_missed"}},[_c('template',{slot:"label"},[_vm._v(" Quantity missing"),_c('RedAsterisk')],1)],2),_c('v-combobox',{attrs:{"items":_vm.computedInventoryTags,"item-text":"inventory_tag","text-val":"item","return-object":false,"rules":[function (v) { return !!v || 'Inventory tag is required'; }],"required":""},model:{value:(_vm.inventoryTag),callback:function ($$v) {_vm.inventoryTag=$$v},expression:"inventoryTag"}},[_c('template',{slot:"label"},[_vm._v(" Inventory tag"),_c('RedAsterisk')],1)],2)],1),(_vm.consumption)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[(_vm.consumption)?_c('p',[_vm._v(" Current Raw Material Image ")]):_vm._e(),(_vm.consumption)?_c('v-img',{attrs:{"src":_vm.convertedPhoto(
                      this.consumption.photo,
                      this.consumption.mime_type
                    ),"contain":"","height":"100%","width":"100%"}}):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.btnSubmitName)+" ")])],1)],1)],1)],1),_c('Loading',{ref:"loading"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }