<template>
  <v-app>
    <template v-if="layout === 'login'">
      <Snackbar ref="snackbar" />
      <router-view />
    </template>
    <template v-else>
      <NavigationDrawer ref="navDrawer" />
      <AppBar @toggle-drawer-size="toggleDrawerSize" />
      <Snackbar ref="snackbar" />
      <v-main>
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import NavigationDrawer from './components/NavigationDrawer.vue';
import AppBar from './components/AppBar.vue';
import Snackbar from './components/Snackbar';
import Bus from './bus';

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    AppBar,
    Snackbar,
  },
  computed: {
    layout() {
      return this.$route.name === 'login' ? 'login' : 'default';
    },
  },
  data: () => ({
    mini: false,
    clients: null,
    jobs: null,
    consumption: null,
    profiles: null,
    inventory: null,
    equipment: null
  }),
  created() {
    this.initialize();

  },
  methods: {
    async initialize() {
      Bus.$on('alert', (type, message) => {
        if (type === 'success') {
          this.$refs.snackbar.showSuccess(message);
        } else {
          this.$refs.snackbar.showError(message);
        }
      });

      if (this.layout === 'default') {
        this.initializeDrawer();
      }
      
    },
    async initializeDrawer() {
      if (screen.width < 1500) {
        this.mini = true;
      }
      await this.$nextTick();
      this.$refs.navDrawer.miniVariant = this.mini;
    },
    toggleDrawerSize() {
      this.$refs.navDrawer.miniVariant = !this.$refs.navDrawer.miniVariant;
    },
    
  },
};
</script>
<style scoped></style>
