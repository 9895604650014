import axios from 'axios';

class Api {
  constructor(model, endpoint) {
    this.model = model;
    this.endpoint = endpoint;
  }

  async getList() {
    let list = [];
    const errorText = `Could not fetch ${this.model} list`;

    try {
      list = await axios.get(`/${this.endpoint}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }

  async store(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async storeFormData(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async softDelete(id){
    let res;
    const errorText = `Could not delete ${this.model}`;

    try {
      res = await axios.delete(`/${this.endpoint}/${id}`);
      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async GetByUrl(url) {
    let res;
    const errorText = `Could not fetch ${this.model} list`;

    try {
      res = await axios.get(`/${this.endpoint}/${url}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }
    return res;
  }
}

export default Api;
