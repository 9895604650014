<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="8" style="text-align:center;margin-top:20px;color:#1976d2">
        <h3>Supply to Line / Return Sheet #{{consumption_id}}</h3>
      </v-col>
      <v-col cols="4" class="d-flex justify-end align-end">
        <v-btn
          color="primary"
          outlined
          class="mr-4"
          @click="openNewRecordForm(null, color, 0)"
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
            New Supply Record
        </v-btn>
        
        <v-btn
          color="primary"
          outlined
          @click="openNewRecordForm(null, color, 1)"
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
            New Return Record
        </v-btn>
      </v-col>
    </v-row>
      <v-row style="background-color:#f6f6f6;margin:10px 0 0 0">
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Job Name: </h5> <span class="value"> {{consumption != null ? consumption.job_name : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Job Address: </h5> <span class="value"> {{consumption != null ? consumption.address_simple : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Sheet Date: </h5> <span class="value"> {{consumption != null ? consumption.sheet_date : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Created At: </h5> <span class="value"> {{consumption != null ? consumption.created_at : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Created By: </h5> <span class="value"> {{consumption != null ? consumption.created_by : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Supervisor: </h5> <span class="value"> {{consumption != null ? consumption.supervisor : ''}}</span>
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">External Colour: </h5> <span class="value"> {{ex_color}}</span>
          
          
        </v-col>
        <v-col cols="3" style="text-align:center">
          <h5 class="h5">Internal Colour: </h5> <span class="value"> {{in_color}}</span>
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        
        <v-data-table
          class="elevation-1"
          id="movements-table"
          :headers="profilesHeaders"
          :items="profiles"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :loading="loading"
          dense
          :item-class="itemRowBackground"
        >
          <template v-slot:[`item.photo`]="{ item }">
            <a @click="openItemShowImageForm(item.photo, item.mime_type, item.material_id)">
              <v-img :src="convertedPhoto(item.photo, item.mime_type)" class="profile-photo"></v-img>
            </a>
          </template>
          <template v-slot:[`item.die`]="{ item }">
              {{ item.material_id }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
              {{ item.name }}
          </template>
          <template v-slot:[`item.color`]="{ item }">
              {{ item.color ? item.color.code : '' }}
          </template>
          <template v-slot:[`item.profile`]="{ item }">
              {{ item.profile }}
          </template>
          <template v-slot:[`item.quantity_supplied`]="{ item }">
              {{ !item.is_return ?  item.quantity_supplied : '0' }}
          </template>
          <template v-slot:[`item.quantity_returned`]="{ item }">
              {{ item.is_return ?  item.quantity_supplied : '0' }}
          </template>
          <template v-slot:[`item.quantity_missed`]="{ item }">
              {{ item.quantity_missed }}
          </template>
          <template v-slot:[`item.inventory_tag`]="{ item }">
              {{ item.inventory_tag }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <a @click="openEditSupplyToLineSheetForm(item, color)" class="mr-2"> EDIT </a>
            <a @click="deleted(item.id)">DELETE</a>
          </template>

          <!-- <template v-slot:item="{ item }">
            <tr>
              <td class="movement-category">
                <v-img :src="convertedPhoto(item.inventory_item.photo, item.inventory_item.mime_type)" class="profile-photo"></v-img>
              </td>
              <td class="movement-code">
                  {{ item.inventory_item.material_id }}
              </td>
              <td class="movement-model">
                 {{ item.inventory_item.name }}
              </td>
              <td class="movement-model">
                 {{ item.color ? item.color.code : '' }}
              </td>
              <td class="movement-model">
                 {{ item.profile }}
              </td>
              <td class="movement-serial-number">
                 {{ item.quantity_supplied }}
              </td>
              <td class="movement-serial-number">
                 {{ item.quantity_missed }}
              </td>
              <td class="movement-serial-number">
                 {{ item.inventory_tag }}
              </td>
              <td class="movement-serial-number">
                 {{ item.created_at }}
              </td>
              <td class="movement-serial-number">
                 {{ item.updated_at }}
              </td>
              <td style="text-align:right;min-width: 124px!important">
                <template>
                  <a @click="openEditSupplyToLineSheetForm(item, {'ex_color':consumption.ex_color.id, 'in_color':consumption.in_color.id})" class="mr-2"> EDIT </a>
                  <a @click="deleted(item.id)">DELETE</a>
                </template>
              </td>
            </tr>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar"></Snackbar>
    <SupplyToLineSheetForm
      ref="supplyToLineSheetFormOld"
      v-on:profile-creation-success="notifyProfileCreationSuccess"
      v-on:profile-creation-fail="notifyProfileCreationFail"
    ></SupplyToLineSheetForm>

    <SupplyToLineSheetRecordForm
      ref="supplyToLineSheetForm"
      v-on:profile-creation-success="notifyProfileCreationSuccess"
      v-on:profile-creation-fail="notifyProfileCreationFail"
      v-on:empty-fields="notifyFieldsEmpty"
      :is-return="isReturn"
    >
    </SupplyToLineSheetRecordForm>
    <EditSupplyToLineSheetForm
      ref="editSupplyToLineSheetForm"
      v-on:profile-creation-success="notifyProfileCreationSuccess"
      v-on:profile-creation-fail="notifyProfileCreationFail"
    ></EditSupplyToLineSheetForm>

    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    ></InventoryItemShowImageForm>
  </v-container>
</template>

<script>
import Snackbar from '../../components/Snackbar';
import SupplyToLineSheetForm from '../../components/forms/SupplyToLineSheetForm';
import EditSupplyToLineSheetForm from '../../components/forms/EditSupplyToLineSheetForm';
import RecordsApi from '../../api/RecordsApi';
import ConsumptionsApi from '../../api/ConsumptionsApi';
import SupplyToLineSheetRecordForm from '../../components/forms/SupplyToLineSheetRecordForm'
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';

export default {
  name: '',
  components: {
    Snackbar,
    SupplyToLineSheetForm,
    EditSupplyToLineSheetForm,
    SupplyToLineSheetRecordForm,
    InventoryItemShowImageForm
  },
  data: () => ({
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    profilesHeaders: [
      { text: 'Image', value: 'photo'},
      { text: 'Material ID', value: 'die' },
      { text: 'Material Description', value: 'description' },
      { text: 'Colour', value: 'color' },
      { text: 'Profile', value: 'profile' },
      { text: 'Quantity Supplied', value: 'quantity_supplied' },
      { text: 'Quantity Returned', value: 'quantity_returned' },
      { text: 'Quantity Missing', value: 'quantity_missed' },
      { text: 'Inventory Tag', value: 'inventory_tag' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    profiles: [],
    canEdit: false,
    photo: null,
    photoMimeType: null,
    color: {ex_color: null, in_color: null},
    ex_color: null,
    in_color: null,
    consumption_id: null,
    consumption: null,
    isReturn: 0

  }),
  filters: {},
  created() {
    
    this.consumption_id = this.$route.params.id;
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;

  },
  methods: {
    async initialize() {
      this.loading = true;

      Promise.all([
       this.fetchConsumptions(),
       this.fetchProfiles(),
       ]);
       
      this.loading = false;
      
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyProfileCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchProfiles();
    },
    notifyProfileCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    notifyFieldsEmpty(){
      this.$refs.snackbar.showError('Fields cannot be empty');
    },
    itemRowBackground(item){
      return item.is_return ? 'green lighten-5' : 'red lighten-5'
    },
    async fetchProfiles() {
      
      const items = async (data) => {
        for(let item of data){

          if(this.color != null){

            item.profile = item.color ? item.color.id == this.color.ex_color ? 'External' : item.color.id == this.color.in_color ? 'Internal' : '' : '';

          }
        }
        return data;
      }
      this.profiles = await items(await RecordsApi.getListByConsumption(this.consumption_id));
  
    },
    async fetchConsumptions() {
     

      this.consumption = await ConsumptionsApi.getSignleById(this.consumption_id);
      this.ex_color = this.consumption.ex_color ? `${this.consumption.ex_color.code ? `${this.consumption.ex_color.code}: ` : ''}${this.consumption.ex_color.name ? this.consumption.ex_color.name : ''}` : '';
      this.in_color = this.consumption.in_color ? `${this.consumption.in_color.code ? `${this.consumption.in_color.code}: ` : ''}${this.consumption.in_color.name ? this.consumption.in_color.name : ''}` : '';
      this.color.ex_color = this.consumption.ex_color ? this.consumption.ex_color.id : null;
      this.color.in_color = this.consumption.in_color ? this.consumption.in_color.id : null;

      if(this.date){

        let newDate = this.date.split(',');
        this.date = newDate[0];
        this.photo = response.photo;
        this.photoMimeType = response.mime_type;

      }
      
        
    },
    openEditSupplyToLineSheetForm(item = null, color = null) {
      if(item !== null) this.isReturn = item.is_return
      this.$refs.supplyToLineSheetForm.openDialog(item, color);
    },
    openProfile(item) {
      this.$router.push(`/Profile/${item.id}`);
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async deleted(id) {
      let confirmation = confirm('Are you sure that you want to delete this record?')
      if(confirmation) {
        const res = await RecordsApi.delete(id);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }        
    },

    openNewRecordForm(item, color, isReturn){
      this.isReturn = isReturn
      this.$refs.supplyToLineSheetForm.openDialog(item,color);
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 100px;
  
}


.h5,
.value {
  display: inline-block;
  font-size: 15px;
}
</style>
