import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const state = {
  token: '',
  clientEnv: '',
  user: {},
  selected_bom: null,
  selected_jobs_tab: null,
  selected_inventory_tab: null,
  selected_equipment_tab: null,
  equipment_list: [],
  jobs_list: [],
  conditions_list: [],
  equipment_movement_form_open: false,
  equipment_movement_form_type: null,
  package_version: process.env.PACKAGE_VERSION || '0',
  excel_file: null,
  business_rules: null,
  physicalCount: null
};
const vuexPersisted = new createPersistedState({
  key: 'zitaMrp',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {},
  plugins: [vuexPersisted],
});
