<template>
  <v-container>
    <v-container
      style="font-size: 1.3em !important;"
      class="mt-3"
      v-if="inventoryTag != null"
    >
      <v-row
        v-if="isCounted"
      >
        <v-col class="pt-1 pb-5">
          <span class="green--text">This Inventory Tag has already been counted</span>
        </v-col>
      </v-row>
      <v-row
        class="pa-0"
        align="center"
        justify="center"
      >
        <v-col
          class="pa-0"
          cols="2"
          style="text-align:right"
        >
          <v-row>
            <v-col>
              <h5 class="h5">
                Inventory Tag
              </h5> <span style="font-size: 1.5em;"> {{ inventoryTag != null ? inventoryTag.inventory_tag : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h5 class="h5">
                QR Code
              </h5> <span style="font-size: 1.5em;"> {{ qrCode }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="pa-0"
          cols="1"
          style="text-align:center"
        >
          <hr style="width: 1px; height: 200px; display: inline-block; color: black;">
        </v-col>
        <v-col
          class="pa-0"
          cols="3"
        >
          <v-img
            :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
            contain
            height="100%"
            width="100%"
            class="profile-photo"
          />
        </v-col>
        <v-col
          class="pa-0"
          cols="5"
        >
          <v-row>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Inventory Item
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.name : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Item Material Id
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.material_id : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Length
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.length.name : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Location
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.location.name : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                UOM
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.uom.name : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Colour
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.color.code : '' }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span v-if="isCounted">Enter recounted quantity</span>
          <span v-else>Enter counted quantity</span>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="5">
          <v-form ref="form">
            <v-text-field
              v-model="quantity"
              :rules="[(v) => !!v || 'Quantity is required']"
              required
              outlined
              type="number"
            >
              <template #label>
                Quantity<RedAsterisk />
              </template>
            </v-text-field>
          </v-form>
        </v-col>
        <v-col cols="4">
          <v-btn
            color="primary"
            x-large
            class="mr-4"
            @click="submit"
          >
            submit
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="redirectBack"
          >
            cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <h2 v-else>
      This QR Code does not exist in AWD ERP. Please check one more time.
    </h2>
    <Loading ref="loading" />
    <Snackbar ref="snackbar" />
  </v-container>
</template>


<script>

import InventoryTagsApi from '@/api/InventoryTagsApi';
import InventoryQrCodesApi from '@/api/InventoryQrCodesApi';
import PhysicalCountApi from '@/api/PhysicalCountApi';

export default {
  
  created() {
    this.qrCode = this.$route.params.code;
    this.initialize();
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    isActivated: false,
    quantity: 0,
    physicalCountList: null,
    physicalCount: null,
    isCounted: false

    
  }),
  methods: {
    async initialize() {
      await this.getInventoryTag();
      this.physicalCountList = this.$store.getters.physicalCount
      this.getPhysicalCount()
    },

    async getInventoryTag() {
        if(!this.qrCode) return 
      var qrItem = await InventoryQrCodesApi.getByCode(this.qrCode);
      if (!qrItem) return;
      var response =  await InventoryTagsApi.getInventoryTag(qrItem.inventory_tag);
      if(!response){
          this.$refs.snackbar.showError()
            return
      }
      this.inventoryTag = response

    },

    async getPhysicalCount(){
        if(!this.inventoryTag) return
        var response = await PhysicalCountApi.getByList(this.physicalCountList.id, this.inventoryTag.inventory_tag)
        if(!response){
          return
        }
        if(response.id != 0) this.isCounted = true;
        this.physicalCount = response
    },

    async submit(){
        if(!this.physicalCountList && !this.inventoryTag) return
        if(!await this.$refs.form.validate()) return

        var data = {
            id: this.physicalCount ? this.physicalCount.id : 0,
            physical_count_list_id: this.physicalCountList.id,
            inventory_tag: this.inventoryTag.inventory_tag,
            quantity: this.quantity
        }

        var response = await PhysicalCountApi.store(data)
        if(!response){
            this.$refs.snackbar.showError()
            return
        }
        
        this.physicalCount && this.physicalCount.id ? this.$refs.snackbar.showSuccess('Physical Count Updated') : this.$refs.snackbar.showSuccess('Physical Count Created')
        this.$refs.loading.loading()
        setTimeout(this.redirectBack, 2000);
    },

    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },

    redirectBack(){
        this.$router.push('/physical-count-qr') 
    }

  }
}
</script>