<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" :persistent="formPersistent">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="formAlert" :type="formAlertType">
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8" class="pb-0">
                  <v-img :src="convertedPhoto(photo, type)" contain height="100%" width="100%"></v-img>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import InventoryItemsApi from '../../api/InventoryItemsApi';
import ColorsApi from '../../api/ColorsApi';
import FinishingsApi from '../../api/FinishingsApi';
import ManufacturersApi from '../../api/ManufacturersApi';
//import InventoryNamesApi from '../../api/InventoryNamesApi';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';
import UomsApi from '../../api/UomsApi';
import MaterialPropertiesApi from '../../api/MaterialPropertiesApi';
import LengthsApi from '../../api/LengthsApi';

export default {
  components: {},
  data: () => ({
    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: '',
    formPersistent: true,
    isEdit: false,
    material_id: '',
    photo: '',
    type: ''
  }),
  watch: {},
  methods: {
    openDialog(photo, type, code) {
      this.dialog = true;
      this.photo = photo;
      this.type = type;
      this.code = code;
      this.dialogTitle = 'Item #' + this.code + ' Image';
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
</style>
