<template>
  <v-container fluid class="px-4">
    <SupplyToLineSheet v-if="selectedTab === 0" v-on:show-boms="showBoms"></SupplyToLineSheet>
  </v-container>
</template>

<script>
import SupplyToLineSheet from './SupplyToLineSheet';

export default {
  name: 'Index',
  components: {
    SupplyToLineSheet,
  },
  data: () => ({
    selectedTab: 0,
    bomCode: '',
    rmaCode: '',
  }),
  filters: {},
  methods: {
    async initialize() {
      const selectedTab = this.$store.getters.selectedJobsTab;
      if (selectedTab) {
        this.selectedTab = selectedTab;
        this.tabChanged();
      }
      const selectedBom = this.$store.getters.selectedBom;
      if (selectedBom) {
        this.bomCode = selectedBom.code;
      }
      const selectedRma = this.$store.getters.selectedRma;
      if (selectedRma) {
        this.rmaCode = selectedRma.code;
      }
    },
    async openBom(bom, type) {
      if (type === 'bom') {
        this.selectedTab = 2;
        this.bomCode = bom.code;
        await this.tabChanged();
      } else {
        this.selectedTab = 4;
        this.rmaCode = bom.code;
        await this.tabChanged();
      }

      this.$nextTick(async () => {
        this.$refs.bom.renderBom(bom);
      });
    },
    async showBoms(jobId, type) {
      if (type === 'bom') {
        this.selectedTab = 1;
        await this.$nextTick();
        await this.$refs.boms.initialize('bom', jobId);
      } else {
        this.selectedTab = 3;
        await this.$nextTick();
        await this.$refs.boms.initialize('rma', jobId);
      }
      this.storeTabChanged();
    },
    changeBom(bom, type) {
      if (type === 'bom') {
        this.bomCode = bom.code;
      } else {
        this.rmaCode = bom.code;
      }
    },
    async tabChanged() {
      // Adjust child components
      await this.$nextTick();
      if (this.selectedTab === 1) {
        await this.$refs.boms.initialize('bom');
      } else if (this.selectedTab === 2) {
        await this.$refs.bom.initialize('bom');
      } else if (this.selectedTab === 3) {
        await this.$refs.boms.initialize('rma');
      } else if (this.selectedTab === 4) {
        await this.$refs.bom.initialize('rma');
      }
      this.storeTabChanged();
    },
    storeTabChanged() {
      // Keep current tab in local storage
      this.$store.commit('storeJobsTab', this.selectedTab);
    },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: none !important;
}
</style>
