var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[_c('v-btn',{staticClass:"d-flex align-self-end",staticStyle:{"margin-top":"0"},attrs:{"color":"primary","outlined":""},on:{"click":function () {
            this$1.$refs.itemForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" new item ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.itemsHeaders,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([(_vm.enabled_item_image=='1')?{key:"item.photo",fn:function(ref){
          var item = ref.item;
return [(item.photo)?_c('a',{on:{"click":function($event){return _vm.openItemShowImageForm(item.photo, item.mime_type, item.material_id)}}},[_vm._v(" SHOW ")]):_vm._e()]}}:null,{key:"item.unique_code",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.unique_code)+" ")])]}},(_vm.enabled_material_id==1)?{key:"item.material_id",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_id)+" ")])]}}:null,{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.manufacturers",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturers ? item.manufacturers : '-')+" ")])]}},{key:"item.uoms",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.uoms)+" ")])]}},{key:"item.material_properties",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_properties ? item.material_properties : '-')+" ")])]}},{key:"item.lengths",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.lengths ? item.lengths : '-')+" ")])]}},{key:"item.colors",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.colors_code ? item.colors_code : '-')+" : "+_vm._s(item.colors ? item.colors : '-')+" ")])]}},{key:"item.finishings",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.finishings ? item.finishings : '-')+" ")])]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_at)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" DELETE ")]),_c('a',{on:{"click":function($event){return _vm.openItemForm(item)}}},[_vm._v(" EDIT ")])]}}],null,true)})],1)],1),_c('Snackbar',{ref:"snackbar"}),_c('InventoryItemForm',{ref:"itemForm",on:{"item-creation-success":_vm.notifyItemCreationSuccess,"item-creation-fail":_vm.notifyItemCreationFail}}),_c('InventoryItemShowImageForm',{ref:"itemShowImageForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }