<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="900px"
      :persistent="true"
    >
      <v-form
        v-model="valid"
        ref="form"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col class="pb-0">
                  <v-autocomplete
                    v-model="category"
                    :items="categories"
                    auto-select-first
                    :item-text="getRawMaterialText"
                    item-value="item"
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item }}
                        </p>
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item }}
                        </p>
                      </span>
                    </template>
                    <template slot="label">
                      Category
                    </template>
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="consumption"
                    :items="computedInventoryitems"
                    :item-text="getRawMaterialText"
                    item-value="item"
                    :rules="[
                      (v) => !!v || 'Raw Material Consumption is required',
                    ]"
                    required
                    return-object
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item.material_id }}: {{ item.name }}
                        </p>
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span
                        class="black--text"
                        v-if="item.address"
                      >
                        <p class="profile-p">
                          {{ item.material_id }}: {{ item.name }}
                        </p>
                      </span>
                      <span
                        class="black--text"
                        v-else
                      >
                        <p class="profile-p">
                          {{ item.material_id }}: {{ item.name }}
                        </p>
                      </span>
                    </template>
                    <template slot="label">
                      Raw Material<RedAsterisk />
                    </template>
                  </v-autocomplete>
                  

                  <v-text-field
                    v-model="quantity_supplied"
                    :rules="[(v) => !!v || 'Quantity supplied is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity supplied<RedAsterisk />
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="quantity_missed"
                    :rules="[(v) => !!v || 'Quantity missing is required']"
                    type="number"
                  >
                    <template slot="label">
                      Quantity missing<RedAsterisk />
                    </template>
                  </v-text-field>

                  <v-combobox 
                    v-model="inventoryTag"
                    :items="computedInventoryTags"
                    item-text="inventory_tag"
                    text-val="item"
                    :return-object="false"
                    :rules="[(v) => !!v || 'Inventory tag is required']"
                    required
                  >
                    <template slot="label">
                      Inventory tag<RedAsterisk />
                    </template>
                  </v-combobox>
                  <!-- <v-autocomplete
                    v-model="inventoryTag"
                    :items="computedInventoryTags"
                    item-text="inventoryTag"
                    item-value="item"
                    :rules="[(v) => !!v || 'Inventory tag is required']"
                    required
                    @input="searchInput"
                    :search-input.sync="searchInput"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item.inventory_tag }}
                        </p>
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text" >
                        <p class="profile-p">
                          {{ item.inventory_tag }}
                        </p>
                      </span>
                    </template>
                    <template slot="label">
                      Inventory tag<RedAsterisk></RedAsterisk>
                    </template>
                  </v-autocomplete> -->
                </v-col>
                <v-col
                  cols="6"
                  v-if="consumption"
                  
                  class="pb-0"
                >
                  <p v-if="consumption">
                    Current Raw Material Image
                  </p>
                  <v-img
                    :src="
                      convertedPhoto(
                        this.consumption.photo,
                        this.consumption.mime_type
                      )
                    "
                    v-if="consumption"
                    contain
                    height="100%"
                    width="100%"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <Loading ref="loading" />
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import InventoryTagsApi from '../../api/InventoryTagsApi';
import RecordsApi from '../../api/RecordsApi';
import UsersApi from '../../api/UsersApi';
import { mapGetters } from 'vuex';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    consumptions: [],
    id: null,
    consumption: null,
    quantity_supplied: '',
    quantity_missed: '',
    photo: null,
    isIdParamActive: false,
    param_id: null,
    item: {},
    inventoryTag: null,
    inventory_item_id: null,
    created_by: null,
    category: null,
    categories: [],
    inventoryTags: [],
    color: null,
    searchInput: null,
    color_id: null,
    address: null,
    consumption_id: null,
    userId: null,
    
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
    inventoryTag: function (val) {
      this.inventoryTag = val ? val.inventory_tag ? val.inventory_tag : val : '';
    },
  },
  computed: {
    ...mapGetters(['user']),
    computedInventoryitems(){

      const itemsColorFiltered = this.color != null 
      ? this.color.ex_color != null || this.color.in_color != null
      ? this.consumptions.filter(item => {
        
          if(item.colors_ids){
            const color_ids_array = item.colors_ids.split(',');
            const result = color_ids_array.filter(color => color == this.color.ex_color || color == this.color.in_color ? true : false);
            return result.length > 0;
          }
          return false;
        }
      ) 
      : this.consumptions 
      : this.consumptions;

      const itemsList = this.category != null ? itemsColorFiltered.filter(item => item.category === this.category) : itemsColorFiltered;

      //return itemsList.sort((a,b) => a.material_id.toUpperCase().localeCompare(b.material_id.toUpperCase()));
      return itemsList.sort((a,b) => {

        if(a.material_id != null) return a.material_id.toUpperCase().localeCompare(b.material_id.toUpperCase())
      });

    },
    computedInventoryTags(){

      return this.consumption != null ? this.inventoryTags.filter(item => item.inventory_item_id === this.consumption.id && (item.color_ids == this.color.ex_color || item.color_ids == this.color.in_color)) : this.inventoryTags;

    },
  },
  created() {
    this.initialize();
    if (this.$route.params.id) {
      this.param_id = this.$route.params.id;
      this.isIdParamActive = true;
    }
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchConsumptions(), this.fetchInventoryTags()]);
      this.userId = this.$store.getters.user.id;
      this.category = this.categories[0];
    },
    openDialog(color = null, consumption_id = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
          this.dialogTitle = 'New Record';
          this.btnSubmitName = 'Create';
          this.color = color;
          this.consumption_id = consumption_id;
          this.useId = this.$store.getters.user.id;
          this.clearForm();
        
      });
      
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.$refs.loading.loading()
        let data = new FormData();
        if (this.consumption) {
          data.append('consumption_id', this.$route.params.id);
          data.append('inventory_item_id', this.consumption.id);
        }

        if (this.id) {
          data.append('id', this.id);
        }

        if (this.quantity_supplied) {
          data.append('quantity_supplied', this.quantity_supplied);
        }

        if (this.quantity_missed) {
          data.append('quantity_missed', this.quantity_missed);
        }

        if (this.inventoryTag) {
          data.append('inventory_tag', this.inventoryTag);
        }
        
        if (this.userId) {
          data.append('created_by', this.userId);
        }

        if(this.color_id){
          data.append('color_id', this.color_id ? this.color_id : null);
        }

        if(this.address){
          data.append('address', this.address ? this.address : null);
        }

        const res = await RecordsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.$refs.loading.loading()
        this.dialog = false;
      }
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.consumption = null;
      this.supervisor = null;
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchConsumptions() {
      
      this.consumptions = await InventoryItemsApi.getList();
      this.categories = [...new Set(this.consumptions.map(item => item.category))]
      
    },
    async fetchInventoryTags(){
      this.inventoryTags = await InventoryTagsApi.getList();
    },
    convertedPhoto(photo, mimeType) {
      if(photo && mimeType)
        return `data:${mimeType};base64, ${photo}`;
    },
    getRawMaterialText(item){
      return `${ item.material_id } ${ item.name }`;
    },
  },
};
</script>
<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}
</style>