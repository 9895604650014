import Api from './Api';
import axios from 'axios';

class JobsApi extends Api {
  constructor() {
    super('job', 'jobs');
  }

  async softDelete(jobId) {
    const errorText = 'Could not delete job';
    let res = null;
    try {
      res = await axios.get(`/jobs/delete/${jobId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        } else {
          res = res.data;
        }
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const jobsApi = new JobsApi();

export default jobsApi;
