var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[_c('v-btn',{staticClass:"d-flex align-self-end",attrs:{"color":"primary","outlined":""},on:{"click":function () {
            this$1.$refs.consumptionForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" New Supply to Line / Return Sheet ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.consumptionsHeaders,"items":_vm.consumptions,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',[_c('td',[_c('a',{on:{"click":function($event){return _vm.openConsumption(item)}}},[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.sheet_date_formated)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.job_name)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.address_simple)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at)+" by "+_vm._s(item.created_by)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_at)+" by "+_vm._s(item.updated_by)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.release_floor)+" ")])],1),_c('td',[_c('TextHighlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.records_number)+" ")])],1),_c('td',{attrs:{"align":"end"}},[_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openConsumption(item)}}},[_vm._v("OPEN")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openConsumptionForm(item)}}},[_vm._v(" EDIT ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteConsumption(item)}}},[_vm._v("DELETE")])])])]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.address ? item.address : '-')+" ")]}},{key:"item.supervisor",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.supervisor ? item.supervisor : '-')+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.canEdit)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openConsumption(item)}}},[_vm._v(" EDIT ")]):_vm._e()]}}],null,true)})],1)],1),_c('Snackbar',{ref:"snackbar"}),_c('ConsumptionForm',{ref:"consumptionForm",on:{"consumption-creation-success":_vm.notifyConsumptionCreationSuccess,"consumption-creation-fail":_vm.notifyConsumptionCreationFail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }