
<template>
  <v-container class="pt-0">
    <v-row>
      <v-col
        class="pb-0"
        style="text-align:center;"
      >
        <h2>QR Code # {{ qrCode }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pa-5"
        v-if="isActivated"
        style="text-align:center;"
      >
        <span style="color:green; font-size: 1.5em">This QR code already has been activated</span>
      </v-col>
      <v-col
        class="pa-0"
        v-else
        style="text-align:center;"
      >
        <span style="color:red; font-size: 1.5em">This QR code has not been activated</span>
      </v-col>
    </v-row>

    <v-row v-if="!isActivated">
      <v-col
        class="pa-0"
        style="text-align:center; font-size: 1.5em"
      >
        <span>To ACTIVATE this QR code, please enter Inventory Tag</span>
      </v-col>
    </v-row>
    <v-row v-if="!isActivated">
      <v-col style="text-align:center">
        <v-form ref="form">
          <v-row>
            <v-col
              class="mt-3"
              cols="4"
              style="text-align:right;"
            >
              <span style="font-size: 1.5em">Inventory Tag<RedAsterisk /></span>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="selectedTag"
                solo
                :rules="[
                  (v) => !!v || 'Inventory Tag is required'
                ]"
              />
            </v-col>
            <v-col
              class="mt-2"
              cols="4"
              style="text-align:left"
            >
              <v-btn
                outlined
                color="primary"
                @click="checkInventoryTag"
              >
                Enter
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
      
      
    <v-container
      style="font-size: 1.3em !important;"
      class="pa-0"
      v-if="inventoryTag != null"
    >
      <v-row
        class="pa-0"
        align="center"
        justify="center"
      >
        <v-col
          class="pa-0"
          cols="2"
          style="text-align:right"
        >
          <h5 class="h5">
            Inventory Tag
          </h5> <span style="font-size: 1.5em;"> {{ inventoryTag != null ? inventoryTag.inventory_tag : '' }}</span>
        </v-col>
        <v-col
          class="pa-0"
          cols="1"
          style="text-align:center"
        >
          <hr style="width: 1px; height: 200px; display: inline-block; color: black;">
        </v-col>
        <v-col
          class="pa-0"
          cols="3"
        >
          <v-img
            :src="convertedPhoto(inventoryTag.item.photo, inventoryTag.item.mime_type)"
            contain
            height="100%"
            width="100%"
            class="profile-photo"
          />
        </v-col>
        <v-col
          class="pa-0"
          cols="5"
        >
          <v-row>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Inventory Item
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.name : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Item Material Id
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.item.material_id : '' }}</span>
            </v-col>
            <v-col
              cols="4"
              style="text-align:center"
            >
              <h5 class="h5">
                Length
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.length.name : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              style="text-align:center"
            >
              <h5 class="h5">
                Location
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.location.name : '' }}</span>
            </v-col>
            <v-col
              cols="3"
              style="text-align:center"
            >
              <h5 class="h5">
                Quantity
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.quantity : '' }}</span>
            </v-col>
            <v-col
              cols="3"
              style="text-align:center"
            >
              <h5 class="h5">
                UOM
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.uom.name : '' }}</span>
            </v-col>
            <v-col
              cols="3"
              style="text-align:center"
            >
              <h5 class="h5">
                Colour
              </h5> <span class="value"> {{ inventoryTag != null ? inventoryTag.color.code : '' }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    
    <v-row
      class="mt-5"
      v-if="errorMessage != null"
    >
      <v-col
        class="pa-0"
        style="text-align:center"
      >
        <span style="color:red; font-size: 1.5em">{{ errorMessage }}</span>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col
        class="pa-0"
        :style="inventoryTag != null && !isActivated ? 'text-align: right;' : 'text-align: center;'"
      >
        <v-btn
          large
          outlined
          color="primary"
          @click="scanNewQrCode"
        >
          Scan new qr code
        </v-btn>
      </v-col>
      <v-col
        class="pa-0"
        v-if="inventoryTag != null && !isActivated"
        cols="1"
      />
      <v-col
        class="pa-0"
        cols="6"
        v-if="inventoryTag != null && !isActivated"
        style="text-align: left;"
      >
        <v-btn
          large
          color="primary"
          @click="submitQRCode"
        >
          {{ activeQrBtn }}
        </v-btn>
      </v-col>
      <v-col
        v-if="addNewInventoryTag"
        style="text-align: left;"
        class="pa-0"
        cols="6"
      >
        <v-btn
          large
          color="primary" 
          @click="() => {
            this.$refs.tagForm.openDialog();
          }
          "
        > 
          Add New Inventory Tag 
        </v-btn>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <inventory-tag-form
      ref="tagForm"
      @submitQRCodeTag="submitQRCodeTag"
      :entered-tag="selectedTag"
    />
  </v-container>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import InventoryTagsApi from '@/api/InventoryTagsApi';
import InventoryQrCodesApi from '@/api/InventoryQrCodesApi';
import Snackbar from '@/components/Snackbar';
import InventoryTagForm from '../../components/forms/InventoryTagForm.vue';

export default {
  components: { Snackbar, RedAsterisk, InventoryTagForm },
  created() {
    this.qrCode = this.$route.params.code;
    this.initialize();
  },
  data: () => ({
    qrCode: '',
    inventoryTag: null,
    isActivated: false,
    selectedTag: '',
    valid: false,
    activeQrBtn: 'Activate Qr Code',
    errorMessage: null,
    addNewInventoryTag: false,
    
  }),
  methods: {
    async initialize() {
      this.getInventoryTag();
    },

    async getInventoryTag() {
      var qrItem = await InventoryQrCodesApi.getByCode(this.qrCode);
      if (!qrItem) return;
      this.inventoryTag = await InventoryTagsApi.getInventoryTag(qrItem.inventory_tag);
      this.isActivated = true
    },
    async getInventoryTagsNames() {
      this.inventoryTagsNames = await InventoryTagsApi.getTagNames();
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async checkInventoryTag(){
      if(this.selectedTag === '') return
      this.addNewInventoryTag = false
      this.errorMessage = null
      this.inventoryTag = await InventoryTagsApi.getInventoryTag(this.selectedTag);
      if(!this.inventoryTag){
        this.errorMessage = 'This Inventory Tag does not exist in AWD ERP. Please check one more time.'
        this.addNewInventoryTag = true
        this.inventoryTag = null
        return
      }
      if(this.inventoryTag.qr_code) {
        this.errorMessage = `IMPORTANT: This Inventory Tag already been activated with QR code # ${this.inventoryTag.qr_code.code}`
        this.activeQrBtn = `DELETE QR CODE ${this.inventoryTag.qr_code.code}, AND ACTIVATE NEW QR CODE ${this.qrCode}`
        return 
      }
      this.activeQrBtn = 'Activate Qr Code'
      
    },
    async submitQRCode(){
      if (this.$refs.form.validate()) {
        var tagCode = {
          inventory_tag: this.selectedTag,
          code: this.qrCode,
        };

        var response = await InventoryQrCodesApi.store(tagCode);

        if (response == true) {

          this.$refs.snackbar.showSuccess('QR code has been activated! Please make sure to attach QR Code to Inventory Tag.');
          this.inventoryTag = null
          this.isActivated = true
          this.errorMessage = null
          this.addNewInventoryTag = false
          this.getInventoryTag();

        } else {
          this.$refs.snackbar.showError(response);
        }
      }
    },
    submitQRCodeTag(newTag){
      this.selectedTag = newTag
      this.submitQRCode()
    },
    scanNewQrCode(){
      this.$router.push('/inventory-qr');
    },
  },
};
</script>
<style scoped>
.profile-photo{

  max-height: 300px;
  max-width: 300px;
  
}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-btn {
    font-size: 1.1em !important;
}

</style>