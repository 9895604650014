// noinspection ES6UnusedImports
import Vue from 'vue';
import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common.Accept = 'application/json';
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      return new Promise((resolve) => {
        store.dispatch('logout').then(() => {
          location.reload();
        });
      });
    }
    return Promise.reject(error);
  }
);
const token = store.getters.userToken;
//const client = store.getters.clientEnv;
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}

Vue.$http = axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return axios;
  },
});
