<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" :persistent="true">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="errorAlert" type="error">
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="12" class="pb-0">  
                  <v-text-field v-model="type_name" :rules="[(v) => !!v || 'Colour name is required']">
                        <template slot="label">
                            Type Name<RedAsterisk></RedAsterisk>
                        </template>
                    </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submitForm">
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';
import UsersApi from '../../api/UsersApi';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    supervisors: [],
    consumptions: [],
    id: null,
    supervisor: null,
    consumption: null,
    Profiles_id: null,
    isIdParamActive: false,
    param_id: null,
    type_name: '',
    type_code: ''
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
  },
  created() {
    this.initialize();
    if(this.$route.params.id) {
      this.param_id = this.$route.params.id;
      this.isIdParamActive = true;
    }
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchConsumptions(), this.fetchForemen()]);
    },
    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit Type ${item.name}  `;
          this.btnSubmitName = 'Update';
          this.loadProfileToForm(item);
        } else {
          this.dialogTitle = 'New Type';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        let data = new FormData();

        if (this.type_name) {
          data.append('name', this.type_name);
          data.append('enabled', 1);
        }

        if(this.id) {
          data.append('id', this.id);
        }

        const res = await InventoryCategoriesApi.storeFormData(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadProfileToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.type_name = item.name;
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.consumption = null;
      this.supervisor = null;
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchConsumptions() {
      this.consumptions = await InventoryCategoriesApi.getList();
    },
    async fetchForemen() {
      this.supervisors = await UsersApi.getListForemen();
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p{
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}</style>