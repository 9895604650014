<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="1500px">
      <v-card tile>
        <v-card-title >
          {{dialogTitle}}
          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon large dark>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
        <v-data-table
          class="elevation-1"
          id="movements-table"
          :headers="profilesHeaders"
          :items="profiles"
          :items-per-page="-1"
          :loading="loading"
          hide-default-footer
          dense
        >
        
          <template v-slot:[`item.photo`]="{ item }">
            
            <a @click="openItemShowImageForm(item.photo, item.mime_type, item.material_id)" v-if="item.photo" >
              <v-img :src="convertedPhoto(item.photo, item.mime_type)" class="profile-photo"></v-img>
              </a>
            
          </template>
          <template v-slot:[`item.die`]="{ item }">
              {{ item.material_id }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
              {{ item.name }}
          </template>
          <template v-slot:[`item.color`]="{ item }">
              {{ item.color ?item.color.code ? item.color.code : item.color.name ? item.color.name : '' : ''}}
          </template>
          <template v-slot:[`item.profile`]="{ item }">
              {{ item.profile }}
          </template>
          <template v-slot:[`item.quantity_supplied`]="{ item }">
              <v-text-field type="number" v-model="item.quantity_supplied">{{item.quantity_supplied}}</v-text-field>
          </template>
          <template v-slot:[`item.quantity_missed`]="{ item }" v-if="isReturn == 0">
              <v-text-field type="number" v-model="item.quantity_missed"></v-text-field>
          </template>
          <template v-slot:[`item.inventory_tag`]="{ item }">
              <v-combobox 
                    v-model="item.inventory_tag"
                    :items="computedInventoryTags(item)"
                    item-text="inventory_tag"
                    text-val="item"
                    :return-object="false"
                    :rules="[(v) => !!v || 'Inventory tag is required']"
                    required
                    ref="form"
                  >
                  </v-combobox >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <a @click="submitForm(item)" class="mr-2"> {{btnSubmitName}} </a>
          </template>
        </v-data-table>
        </v-card-text>
        </v-card>
    </v-dialog>

    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    ></InventoryItemShowImageForm>
    
  </v-row>
</template>

<script>
import RecordsApi from '../../api/RecordsApi';
import ConsumptionsApi from '../../api/ConsumptionsApi';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import InventoryTagsApi from '../../api/InventoryTagsApi';
import InventoryItemShowImageForm from '../../components/forms/InventoryItemShowImageForm';


export default{
  props:
  {
    isReturn: {
      type: Number,
      default: 0
    }
  },
  components: {
    InventoryItemShowImageForm
  },
  data: () => ({
      loading: false,
      profiles: [],
      consumption: null,
      consumption_id: null,
      color: {ex_color: null, in_color: null},
      inventoryTags: [],
      dialog: false,
      inventoryItems: [],
      itemsList: [],
      quantitySupplied: 0,
      quantityMissing: 0,
      inventoryTag: '',
      dialogTitle: '',
      userId: 0,
      editRecord: null,
      btnSubmitName: ''


  }),
  created() {
    
    if (this.$route.params.id) {
        this.consumption_id = this.$route.params.id;
    }
    this.initialize();
  },
  computed: {
      profilesHeaders(){
        var headers = []
        headers.push({ text: 'Image', value: 'photo'})
        headers.push({ text: 'Material ID', value: 'die' })
        headers.push({ text: 'Material Description', value: 'description' })
        headers.push({ text: 'Colour', value: 'color' })
        headers.push({ text: 'Profile', value: 'profile' })

        this.isReturn === 0 ? headers.push({ text: 'Quantity Supplied', value: 'quantity_supplied' }) : headers.push({ text: 'Quantity Returned', value: 'quantity_supplied' })
        if(this.isReturn === 0) headers.push({ text: 'Quantity Missing', value: 'quantity_missed' })
        headers.push({ text: 'Inventory Tag', value: 'inventory_tag' })
        headers.push({ text: 'Actions', value: 'actions', align: 'end' })

        return headers
      },
      
      
      
  },

  watch: {
    editRecord: async function () {
      await this.fetchProfiles();
    },
  },

  methods: {
    async initialize() {
      this.loading = true;
      this.userId = this.$store.getters.user.id;
      Promise.all([
            this.fetchInventoryItems(),
            this.fetchConsumption(),
            this.fetchInventoryTags(),
       ])
       .then(() => {
         this.fetchProfiles()
         this.loading = false;
        })
    },
    openDialog(item = null, color = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {

          if(item != null){
            this.dialogTitle = `Edit Record #${item.id}`;
            this.btnSubmitName = 'UPDATE';
            this.editRecord = item;
          }

          else{

            this.dialogTitle = this.isReturn ? 'New Return Record' : 'New Supply Record';
            this.btnSubmitName = 'ADD';
            this.editRecord = null;

          }
          
          this.color = color;
          this.useId = this.$store.getters.user.id;
        
      });
      
    },
    async fetchProfiles() {
      
      const items = async (data) => {
        for(let item of data){

           if(this.color != null){
            
            item.profile = item.name.toUpperCase().includes('EXTERIOR') || item.name.toUpperCase().includes('EXT') ? 'External' : item.name.toUpperCase().includes('INTERIOR') || item.name.toUpperCase().includes('INT') ? 'Internal' : ''
            item.profile = item.profile !== '' ? item.profile : item.colors_ids.includes(this.color.ex_color) ? 'External' : item.colors_ids.includes(this.color.in_color) ? 'Internal' : '';
            item.color = {name: null, color_id: null}
            item.color.name = item.colors_ids.includes(this.color.ex_color) ? this.consumption.ex_color.code : item.colors_ids.includes(this.color.in_color) ? this.consumption.in_color.code : '';
            item.color.color_id = item.colors_ids.includes(this.color.ex_color) ? this.consumption.ex_color.id : item.colors_ids.includes(this.color.in_color) ? this.consumption.in_color.id : null;
            item.quantity_missed = 0;
          }
        }
        return data;
      }
      if(this.editRecord != null){
        this.profiles = []
        this.profiles.push(this.editRecord)
      }else{
        this.profiles = await items(this.getFilteredItems());
      }


  
    },
    getFilteredItems(){
      const itemsColorFiltered = this.color != null 
      ? this.color.ex_color != null || this.color.in_color != null
      ? this.inventoryItems.filter(item => {
        
          if(item.colors_ids){
            const color_ids_array = item.colors_ids.split(',');
            const result = color_ids_array.filter(color => color == this.color.ex_color || color == this.color.in_color ? true : false);
            return result.length > 0;
          }
          return false;
        }
      ) 
      : this.inventoryItems 
      : this.inventoryItems;

      const itemsList = this.category != null ? itemsColorFiltered.filter(item => item.category === this.category) : itemsColorFiltered;

      return itemsList.sort((a,b) => {

        if(a.material_id != null) return a.material_id.toUpperCase().localeCompare(b.material_id.toUpperCase())
      });

    },
    async fetchConsumption(){
        this.consumption = await ConsumptionsApi.getSignleById(this.consumption_id);
        this.color.ex_color = this.consumption.ex_color ? this.consumption.ex_color.id : null;
        this.color.in_color = this.consumption.in_color ? this.consumption.in_color.id : null;
    },
    async fetchInventoryTags(){
      this.inventoryTags = await InventoryTagsApi.getList();
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async fetchInventoryItems() {
      
      this.inventoryItems = await InventoryItemsApi.getList();
      this.categories = [...new Set(this.inventoryItems.map(item => item.category))]
      
    },
    async submitForm(item){
      if(!item.inventory_tag || !item.quantity_supplied){
        this.$emit('empty-fields');
        return
      } 
      var data;
      if(this.editRecord != null){
        
        data = {
          id: item.id,
          consumption_id: this.consumption_id,
          inventory_item_id: item.inventory_item_id,
          quantity_supplied: item.quantity_supplied,
          quantity_missed: item.quantity_missed,
          inventory_tag: item.inventory_tag,
          created_by: this.userId,
          is_return: this.isReturn

        }

      }else{

        data = {
        consumption_id: this.consumption_id,
        inventory_item_id: item.id,
        quantity_supplied: item.quantity_supplied,
        quantity_missed: item.quantity_missed,
        inventory_tag: item.inventory_tag,
        created_by: this.userId,
        is_return: this.isReturn
      }
      }
      

      const res = await RecordsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        //this.dialog = false;
    },

    computedInventoryTags(record){
      if(record.inventory_item_id) 
        return record != null ? this.inventoryTags.filter(item => item.inventory_item_id === record.inventory_item_id && (item.color_ids == this.color.ex_color || item.color_ids == this.color.in_color)) : this.inventoryTags;
      else
         return record != null ? this.inventoryTags.filter(item => item.inventory_item_id === record.id && (item.color_ids == this.color.ex_color || item.color_ids == this.color.in_color)) : this.inventoryTags;

    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

  },


}

</script>
<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}
</style>