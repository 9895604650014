import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        colorMrpGrey: '#323232',
        colorMrpRed: '#ED1C24',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'fa',
  },
});
