<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-data-table
          class="elevation-1"
          id="movements-table"
          :headers="businessRulesHeaders"
          :items="businessRules"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr>
              <td>
                <TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.name }}
                </TextHighlight>
              </td>
              <td align="end">
                <v-checkbox
                  false-value="0"
                  true-value="1"
                  v-model="item.value" 
                  @click="changeBusinessRule(item)"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import BusinessRulesApi from '../../api/BusinessRulesApi';
import TextHighlight from 'vue-text-highlight';
import Bus from '../../bus';

export default {
    components: {
        TextHighlight
    },
    data: () => ({
        search: '',
        loading: true,
        itemsTableFooterProps: {
        'items-per-page-options': [10, 20, 50, -1],
        },
        businessRulesHeaders: [
            { text: 'Rule Id', value: 'id', width: '5%' },
            { text: 'Rule Name', value: 'name' },
            { text: 'Actions', align: 'end', width: '10%' },
            
        ], 
        businessRules: [],

        
    }),
     created() {
        this.initialize();
    },
    methods: {
        initialize() {
          Promise.all([this.getBusinessRules()]);
          this.loading = false;
        },

        async getBusinessRules(){

            this.businessRules = await BusinessRulesApi.getList();

        },

        async changeBusinessRule(item){

          const res = await BusinessRulesApi.store(item);
          if (res == true){
            var businessRules = await BusinessRulesApi.getList();
            this.$store.commit('storeBusinessRules', businessRules);
            Bus.$emit('updateBusinessRules');
          }
        }
        
    }

}


</script>