<template>
  <v-container
    class="pa-0"
    fluid
  >
    <form>
      <v-row>
        <v-col>
          <v-text-field
            v-model="org_name"
            label="Organization Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="url"
            label="Organization URL"
            readonly
            disabled
            @input="$v.url.$touch()"
            @blur="$v.url.$touch()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="end">
          <v-btn
            @click="submitForm"
            color="primary"
          >
            submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col
        cols="12"
        class="pt-4 mt-4"
      >
        <h4>Modules</h4>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled"
          @click="changeClients(enabled)"
        >
          <template #label>
            <div>Clients</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_jobs"
          @click="changeJobs(enabled_jobs)"
        >
          <template #label>
            <div>Jobs</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_consumption"
          @click="changeConsumption(enabled_consumption)"
        >
          <template #label>
            <div>Raw Material Consumption</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_inventory"
          @click="changeInventory(enabled_inventory)"
        >
          <template #label>
            <div>Inventory</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_equipment"
          @click="changeEquipment(enabled_equipment)"
        >
          <template #label>
            <div>Equipment</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_inventory_qr"
          @click="changeInventoryQr(enabled_inventory_qr)"
        >
          <template #label>
            <div>Inventory QR</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox
          v-model="enabled_physical_count_qr"
          @click="changePhysicalCountQr(enabled_physical_count_qr)"
        >
          <template #label>
            <div>Physical Count</div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Bus from '../../bus';
import OrganizationsApi from '../../api/OrganizationsApi';
import ModulesApi from '../../api/ModulesApi';

export default {
  name: 'Organization',
  data: () => ({
    // Page
    name: '',
    url: '',
    search: '',
    loading: true,
    models: [],
    clients: null,
    jobs: null,
    consumption: null,
    profiles: null,
    inventory: null,
    equipment: null,
    enabled: null,
    enabled_jobs: null,
    enabled_consumption: null,
    enabled_inventory: null,
    enabled_equipment: null,
    enabled_material_id: null,
    enabled_unique_item_code: null,
    enabled_inventory_qr: null,
    enabled_physical_count_qr: null,
    org_name: '',
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchOrg(), this.fetchModules()]);
      // this.fetchUsers();
    },
    openUserForm(item) {
      this.$refs.userForm.openDialog(item);
    },
    async changeUserStatus(item) {
      const res = await UsersApi.changeStatus(item.id);
      if (res) {
        Bus.$emit('alert', 'success', 'Success!');
        // this.fetchUsers();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    async changeClients(enabled) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled = enabled;
        if (enabled) {
          const data = {
            id: 1,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 1,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled === true) {
          document.getElementById('clients').style.display = 'flex';
        } else {
          document.getElementById('clients').style.display = 'none';
        }
      }
    },
    async changeJobs(enabled_jobs) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_jobs = enabled_jobs;
        if (enabled_jobs) {
          const data = {
            id: 2,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 2,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_jobs === true) {
          document.getElementById('jobs').style.display = 'flex';
        } else {
          document.getElementById('jobs').style.display = 'none';
        }
      }
    },
    async changeConsumption(enabled_consumption) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_consumption = enabled_consumption;
        if (enabled_consumption) {
          const data = {
            id: 3,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 3,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_consumption === true) {
          document.getElementById('consumptions').style.display = 'flex';
        } else {
          document.getElementById('consumptions').style.display = 'none';
        }
      }
    },
    async changeInventory(enabled_inventory) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_inventory = enabled_inventory;
        if (enabled_inventory) {
          const data = {
            id: 5,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 5,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_inventory === true) {
          document.getElementById('inventory').style.display = 'flex';
        } else {
          document.getElementById('inventory').style.display = 'none';
        }
      }
    },
    async changeEquipment(enabled_equipment) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_equipment = enabled_equipment;
        if (enabled_equipment) {
          const data = {
            id: 6,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 6,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_equipment === true) {
          document.getElementById('equipment').style.display = 'flex';
        } else {
          document.getElementById('equipment').style.display = 'none';
        }
      }
    },
    async changeInventoryQr(enabled_inventory_qr){
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_inventory_qr = enabled_inventory_qr;
        if (enabled_inventory_qr) {
          const data = {
            id: 9,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 9,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_inventory_qr === true) {
          document.getElementById('inventory-qr').style.display = 'flex';
        } else {
          document.getElementById('inventory-qr').style.display = 'none';
        }
      }
    },
    async changePhysicalCountQr(enabled_physical_count_qr){
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_physical_count_qr = enabled_physical_count_qr;
        if (enabled_physical_count_qr) {
          const data = {
            id: 10,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 10,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
        if (this.enabled_physical_count_qr === true) {
          document.getElementById('physical-count-qr').style.display = 'flex';
        } else {
          document.getElementById('physical-count-qr').style.display = 'none';
        }
      }
    },
    async changeMaterialID(enabled_material_id) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_material_id = enabled_material_id;
        if (enabled_material_id) {
          const data = {
            id: 7,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 7,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
      }
    },
    async changeUniqueItemCode(enabled_unique_item_code) {
      let confirmation = confirm(
        'Are you sure that you want to change the enabled?'
      );
      if (confirmation) {
        this.enabled_unique_item_code = enabled_unique_item_code;
        if (enabled_unique_item_code) {
          const data = {
            id: 8,
            enabled: 1,
          };
          const res = await ModulesApi.store(data);
        } else {
          const data = {
            id: 8,
            enabled: 0,
          };
          const res = await ModulesApi.store(data);
        }
      }
    },
    async fetchOrg() {
      this.org = await OrganizationsApi.getList();
      this.org_name = this.org[0].name;
      this.url = this.org[0].url;
    },
    async submitForm() {
      let confirmation = confirm(
        'Are you sure that you want to update the organization info?'
      );
      if (confirmation) {
        const data = {
          id: 1,
          name: this.org_name,
          url: this.url,
        };

        const res = await OrganizationsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        window.location.reload(true);
      }
    },
    async fetchModules() {
      this.modules = await ModulesApi.getList();
      this.name = this.modules[0].name;
      this.enabled = this.modules[0].enabled;
      this.enabled_jobs = this.modules[1].enabled;
      this.enabled_consumption = this.modules[2].enabled;
      this.enabled_inventory = this.modules[3].enabled;
      this.enabled_equipment = this.modules[4].enabled;
      this.enabled_material_id = this.modules[5].enabled;
      this.enabled_unique_item_code = this.modules[6].enabled;
      this.enabled_inventory_qr = this.modules[7].enabled;
      this.enabled_physical_count_qr = this.modules[8].enabled;
      if (this.enabled === 0) {
        if(document.getElementById('clients'))document.getElementById('clients').style.display = 'none';
      } else {
        if(document.getElementById('clients'))document.getElementById('clients').style.display = 'flex';
      }
      if (this.enabled_jobs === 0) {
        if(document.getElementById('jobs'))document.getElementById('jobs').style.display = 'none';
      } else {
        if(document.getElementById('jobs'))document.getElementById('jobs').style.display = 'flex';
      }
      if (this.enabled_consumption === 0) {
        if(document.getElementById('consumptions'))document.getElementById('consumptions').style.display = 'none';
      } else {
        if(document.getElementById('consumptions'))document.getElementById('consumptions').style.display = 'flex';
      }
      if (this.enabled_profiles === 0) {
        if(document.getElementById('profiles'))document.getElementById('profiles').style.display = 'none';
      } else {
        if(document.getElementById('profiles'))document.getElementById('profiles').style.display = 'flex';
      }
      if (this.enabled_inventory === 0) {
        if(document.getElementById('inventory'))document.getElementById('inventory').style.display = 'none';
      } else {
        if(document.getElementById('inventory'))document.getElementById('inventory').style.display = 'flex';
      }
      if (this.enabled_equipment === 0) {
        if(document.getElementById('equipment'))document.getElementById('equipment').style.display = 'none';
      } else {
        if(document.getElementById('equipment'))document.getElementById('equipment').style.display = 'flex';
      }
      if (this.enabled_inventory_qr === 0) {
        if(document.getElementById('inventory-qr'))document.getElementById('inventory-qr').style.display = 'none';
      } else {
        if(document.getElementById('inventory-qr'))document.getElementById('inventory-qr').style.display = 'flex';
      }
      if (this.enabled_physical_count_qr === 0) {
        if(document.getElementById('physical-count-qr'))document.getElementById('physical-count-qr').style.display = 'none';
      } else {
        if(document.getElementById('physical-count-qr'))document.getElementById('physical-count-qr').style.display = 'flex';
      }
    },

    // async fetchUsers() {
    //   this.loading = true;
    //   this.users = await UsersApi.getList();
    //   this.loading = false;
    // },
  },
};
</script>
<style scoped></style>
