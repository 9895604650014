import Api from './Api';

class ModulesApi extends Api {
  constructor() {
    super('admin_module', 'admin_modules');
  }
}
const modulesApi = new ModulesApi();

export default modulesApi;
