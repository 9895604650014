<template>
  <v-app-bar
    class="zm-toolbar"
    app
  >
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer-size')" />
    <v-toolbar-title v-html="pageTitle" />
    <v-spacer />
    <h2 class="headline font-weight-medium primary--text">
      {{ name }}
    </h2>
  </v-app-bar>
</template>
<script>
import OrganizationsApi from '@/api/OrganizationsApi';
import PhysicalCountListApi from '@/api/PhysicalCountListApi'
export default {
  data: () => ({
    name: '',
    url: '',
  }),
  computed: {
    pageTitle: function () {
      if(this.$route.meta.physicalCount){
        var physicalCount = this.$store.getters.physicalCount
        return `Active Physical Count <span class="text-h5">"${physicalCount.name}"</span>, as of ${new Date(`${physicalCount.date} 00:00`).toDateString().substring(4)}`
      }
      return this.$route.meta.title;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchOrg()]);
      var physicalCount = await PhysicalCountListApi.getActive()
      this.$store.commit('storeActivatedPhysicalCount', physicalCount);
    },
    async fetchOrg() {
      this.org = await OrganizationsApi.getList();
      this.name = this.org[0].name;
      this.url = this.org[0].url;
    },
  }
};
</script>
<style>
.zm-toolbar {
  box-shadow: none !important;
  border-bottom: 1px solid #d6d6d6 !important;
}
</style>
