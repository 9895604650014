<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="9"
        class="d-flex justify-end"
        v-if="canEdit"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.InventoryCategoriesForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Type
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          id="movements-table"
          :headers="inventoryTypesHeaders"
          :items="inventoryTypes"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr>
              <td :class="{ 'red-theme': !item.enabled }">
                <TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': !item.enabled }">
                <TextHighlight :queries="search">
                  {{ item.name }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': !item.enabled }">
                <TextHighlight :queries="search">
                  {{ item.created_at }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': !item.enabled }">
                <TextHighlight :queries="search">
                  {{ item.updated_at }}
                </TextHighlight>
              </td>
              <td style="text-align: right">
                <template>
                  <a
                    @click="openProfileForm(item)"
                    class="mr-2"
                  > EDIT </a>
                  <a
                    @click="deleted(item.id)"
                    class="mr-2"
                  >DELETE</a>
                </template>
                <template v-if="item.enabled">
                  <a @click="disable(item.id)">DISABLE</a>
                </template>
                <template v-else>
                  <a @click="enable(item.id)">ENABLE</a>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <InventoryCategoriesForm
      ref="InventoryCategoriesForm"
      @profile-creation-success="notifyProfileCreationSuccess"
      @profile-creation-fail="notifyProfileCreationFail"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Snackbar from '../../components/Snackbar';
import InventoryCategoriesForm from '../../components/forms/InventoryCategoriesForm';
import InventoryCategoriesApi from '../../api/InventoryCategoriesApi';

export default {
  name: 'Profiles',
  components: {
    TextHighlight,
    Snackbar,
    InventoryCategoriesForm,
  },
  data: () => ({
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    inventoryTypesHeaders: [
      { text: 'Type ID', value: 'id' },
      { text: 'Type Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', align: 'end' },

      // { text: 'Updated At', value: 'updated_at' },
      // { text: 'Actions', value: 'actions', align: 'end' },
    ],
    inventoryTypes: [],
    canEdit: false,
  }),
  filters: {},
  created() {
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchProfiles()]);
    },
    openProfileForm(item) {
      this.$refs.InventoryCategoriesForm.openDialog(item);
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyProfileCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchProfiles();
    },
    notifyProfileCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchProfiles() {
      this.loading = true;
      this.inventoryTypes = await InventoryCategoriesApi.getList();

      let arr = this.inventoryTypes;
      let resultsArr = [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].deleted != 1) resultsArr.push(arr[i]);
      }
      this.inventoryTypes = resultsArr;
      this.loading = false;
    },
    openProfile(item) {
      this.$router.push(`/profile/${item.code}`);
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async deleted(id) {
      let confirmation = confirm('Are you sure that you want to delete?');
      if (confirmation) {
        let data = new FormData();
        data.append('id', id);
        data.append('deleted', 1);
        const res = await InventoryCategoriesApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
    async enable(id) {
      let confirmation = confirm('Are you sure that you want to enable?');
      if (confirmation) {
        let data = new FormData();
        data.append('id', id);
        data.append('enabled', 1);
        const res = await InventoryCategoriesApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
    async disable(id) {
      let confirmation = confirm('Are you sure that you want to disable?');
      if (confirmation) {
        let data = new FormData();
        data.append('id', id);
        data.append('enabled', 0);
        const res = await InventoryCategoriesApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
  },
};
</script>
<style scoped>
.profile-photo {
  max-width: 100px;
}
.text-right {
  width: 85px !important;
}
.red-theme {
  color: #fa7575 !important;
}
</style>
