var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openPhysicalCountForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Physical Count ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{ref:"pcTable",attrs:{"headers":_vm.headers,"items":_vm.physicalCountLists,"footer-props":_vm.itemsTableFooterProps,"items-per-page":_vm.itemsPerPageProps,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.user.first_name) + " " + (item.user.last_name)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"#bf0000","text-color":"white","small":""}},[_vm._v(" ACTIVATED ")]):_c('a',{staticClass:"mr-5",on:{"click":function($event){return _vm.activate(item)}}},[_vm._v(" ACTIVATE ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.editForm(item)}}},[_vm._v(" EDIT ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1),_c('physical-count-list-form',{ref:"listForm",attrs:{"physical-count-prop":_vm.physicalCount},on:{"list-form-success":_vm.listFormSuccess}}),_c('Snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }