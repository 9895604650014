<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="true"
    >
      <v-form
        v-model="valid"
        ref="form"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-if="enabled_bom_rma == 1"
                    v-model="client"
                    :items="clients"
                    item-text="address"
                    item-value="id"
                    :clearable="true"
                  >
                    <template slot="label">
                      Client
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-else
                    v-model="client"
                    :items="clients"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                  >
                    <template slot="label">
                      Client
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="supervisor"
                    :items="supervisors"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Supervisor is required']"
                    required
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span class="black--text">
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                    </template>
                    <template slot="label">
                      Person Responsible (Supervisor, Foreman, Project Manager)<RedAsterisk />
                    </template>
                  </v-autocomplete>
                  <v-text-field v-model="name">
                    <template slot="label">
                      Job Name
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import ClientsApi from '../../api/ClientsApi';
import JobsApi from '../../api/JobsApi';
import UsersApi from '../../api/UsersApi';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    supervisors: [],
    clients: [],
    id: null,
    supervisor: null,
    client: null,
    name: '',
    enabled_bom_rma: null,
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchClients(), this.fetchForemen()]);
      this.enabled_bom_rma = this.$store.getters.businessRules.find(x => x.name == 'Bom/rma code').value;
    },
    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit Job #${item.code}`;
          this.btnSubmitName = 'Update';
          this.loadJobToForm(item);
        } else {
          this.dialogTitle = 'New Job';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.userId = this.$store.getters.user.id;
        const data = {
          id: this.id,
          client_id: this.client || null,
          supervisor_id: this.supervisor,
          name: this.name,
          created_by: this.userId
        };
        const res = await JobsApi.store(data);
        if (res) {
          this.$emit('job-creation-success');
        } else {
          this.$emit('job-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadJobToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.client = item.client_id;
      this.supervisor = item.supervisor_id;
      this.name = item.name;
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.client = null;
      this.supervisor = null;
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchClients() {
      
      this.clients = await ClientsApi.getList();
    },
    async fetchForemen() {
      this.supervisors = await UsersApi.getListForemen();
    },
  },
};
</script>
