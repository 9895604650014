import XLSX from 'xlsx';
import FileSaver from 'file-saver';

function s2ab(s) {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}

const ExcelHelper = {
  writeJson: (data, sheetName) => {
    let wb = XLSX.utils.book_new();
    wb.SheetNames.push(sheetName);
    let ws = XLSX.utils.json_to_sheet(data);
    wb.Sheets[sheetName] = ws;
    return wb;
  },
  saveExcelToBrowser: (wb, fileName) => {
    let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      `${fileName}.xlsx`
    );
  },
  excelToJson: async (file) =>{
    return new Promise((resolve, reject) =>{
      var reader = new FileReader();

      reader.onload = (event) =>{
        var data = event.target.result;
        var workbook = XLSX.read(data, { type : 'binary'});
  
        var row = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
        var json_obj = JSON.stringify(row);
        
        resolve(JSON.parse(json_obj));
      }
  
      reader.readAsBinaryString(file);
    })
   

  }
};

export default ExcelHelper;

// reader.onload = (e) =>{
//   var data = new Uint8Array(e.target.result);
//   var workbook = XLSX.read(data, { type : 'array'});
//   var firstSheet = workbook.Sheets[workbook.SheetNames[0]];

//   var result = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });


//   //var row = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
//   var json_obj = JSON.stringify(result, null, 2);
//   resolve(json_obj);
// }
