import axios from 'axios';

export const login = ({ commit }, user) => {
  return new Promise((resolve, reject) => {
    commit('auth_request');
    axios({
      url: '/users/authenticate',
      data: user,
      method: 'POST',
    })
      .then((resp) => {
        const user = resp.data.user;
        const token = resp.data.token;
        axios.defaults.headers.common['Authorization'] = token;
        commit('auth_success', { user: user, token: token });
        resolve(resp);
      })
      .catch((err) => {
        localStorage.removeItem('token');
        reject(err);
      });
  });
};
// export const setClientEnv = ({ commit }, clientEnv) => {
//   return new Promise((resolve) => {
//     commit('set_client_env', clientEnv);
//     resolve();
//   });
// };

export const logout = ({ commit }) => {
  return new Promise((resolve) => {
    commit('logout');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    resolve();
  });
};

export default {
  login,
  logout,
};
