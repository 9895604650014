<template>
    <v-container>
        
        <input type="file" ref="file">
         <v-btn
          color="primary"
          @click="selectFile()"
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
          IMPORT EXCEL
        </v-btn>
        
        <Snackbar ref="snackbar"></Snackbar>
        <v-spacer></v-spacer>
        <p>Important! It will override all current records.</p>
        <span>{{ updatedExcel ? updatedExcel.name ? updatedExcel.name : '-' : '-' }}</span>
        <v-spacer></v-spacer>
        <span>Last Time Updated at {{updatedExcel ? updatedExcel.updated_at ? updatedExcel.updated_at : '-' : '-'}}</span>
        <v-spacer></v-spacer>
        <v-flex v-if="submitted" >
            <v-progress-linear 
                :indeterminate="true"
            ></v-progress-linear>
        </v-flex>
        

         
    </v-container>
    
</template>


<script>
import ExcelHelper from '../../helpers/ExcelHelper';
import InventoryTagsApi from '../../api/InventoryTagsApi';
import Snackbar from '../../components/Snackbar';
import moment from 'moment';


export default {
    components: {
        Snackbar,
    },
    data: () => ({
        updatedExcel: null,
        submitted: false
        
    }),
     created() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.updatedExcel = this.$store.getters.lastUpdatedExcelFile;
            
        },
        selectFile(){
            let excelFile = this.$refs.file;
            
            if(excelFile.files && excelFile.files.length !== 0){
                this.convertExcel(excelFile.files[0]);
            }
            
        },
        async convertExcel(excelFile){
            if(confirm('Important! Please note that all old records will be deleted')){
                this.submitted = true;
                const jsonFile = await ExcelHelper.excelToJson(excelFile);
                
                const res = await InventoryTagsApi.store(jsonFile);
                
                if (res) {
                    this.$refs.snackbar.showSuccess('Success!');
                    const updatedExcel = {name: excelFile.name, updated_at: moment().format('MMMM Do YYYY, h:mm:ss a')}
                    this.$store.commit('storeUpdateExcelFile', updatedExcel);
                    this.updatedExcel = updatedExcel;
                    
                } else {
                    this.$refs.snackbar.showError('Something went wrong! Plese, make sure all fields are filled.');
                }
                this.submitted = false;

            }
        },
    }
}


</script>
