import Api from './Api';
import axios from 'axios';

class TransactionsApi extends Api {
  constructor() {
    super('transaction', 'transactions');
  }

  async getList(filters) {
    let list = [];
    const errorText = 'Could not fetch transactions list';

    try {
      list = await axios.post('/transactions', filters);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getCurrentLevels() {
    let list = [];
    const errorText = 'Could not fetch current levels';

    try {
      list = await axios.get('/transactions/current_levels');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getCurrentLevel(
    inventoryItemId,
    manufacturerId,
    materialPropertyId,
    lengthId,
    colorId,
    finishingId,
    uomId
  ) {
    let res = 0;
    const errorText = 'Could not fetch current level';
    const props = {
      inventory_item_id: inventoryItemId,
      manufacturer_id: manufacturerId,
      material_property_id: materialPropertyId,
      length_id: lengthId,
      color_id: colorId,
      finishing_id: finishingId,
      uom_id: uomId,
    };

    try {
      res = await axios.post('/transactions/current_level', props);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = 0;
    }

    return res;
  }
}
const transactionsApi = new TransactionsApi();

export default transactionsApi;
