import Api from './Api';
import axios from 'axios';

class UsersApi extends Api {
  constructor() {
    super('user', 'users');
  }
  async getListForemen() {
    let list = [];
    const errorText = 'Could not fetch foremen list';
    try {
      list = await axios.get('/users/foremen');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }
  async changeStatus(userId) {
    let res = 0;
    const errorText = 'Could not change user status';

    try {
      res = await axios.get(`/users/change_status/${userId}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = 0;
    }

    return res;
  }

  async createSimple(data) {
    let res;

    const errorText = 'Could not add new Worker';

    try {
      res = await axios.post('users/create_simple', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(e);
      res = false;
    }

    return res;
  }

  async checkUsername(username){
    const errorText = 'Could not change user status';
    let res;
    try {
      res = await axios.get(`/users/username/${username}`);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }
}
const usersApi = new UsersApi();

export default usersApi;
