import Api from './Api';
import axios from 'axios';

class BomRmaItemsApi extends Api {
  constructor() {
    super('bom_rma_item', 'bom_rma_items');
  }

  async getListByBom(bomId) {
    let list = [];
    const errorText = 'Could not fetch Bom Items list';

    try {
      list = await axios.get(`/bom_rma_items/${bomId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async softDelete(bomItemId) {
    const errorText = 'Could not delete item';
    let res = null;
    try {
      res = await axios.get(`/bom_rma_items/soft_delete/${bomItemId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        } else {
          res = res.data;
        }
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const bomRmaItemsApi = new BomRmaItemsApi();

export default bomRmaItemsApi;
