var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canEdit)?_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[_c('v-btn',{staticClass:"d-flex align-self-end",attrs:{"color":"primary","outlined":""},on:{"click":function () {
            this$1.$refs.jobForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Job ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.jobsHeaders,"items":_vm.jobs,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.address ? item.address : '-')+" ")]}},{key:"item.supervisor",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.supervisor ? item.supervisor : '-')+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.enabled_bom_rma == 1)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.showBoms(item, 'bom')}}},[_vm._v(" BOMs ")]):_vm._e(),(_vm.enabled_bom_rma == 1)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.showBoms(item, 'rma')}}},[_vm._v(" RMAs ")]):_vm._e(),(_vm.canEdit)?_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openJobForm(item)}}},[_vm._v(" EDIT ")]):_vm._e(),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteJob(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1),_c('Snackbar',{ref:"snackbar"}),_c('JobForm',{ref:"jobForm",on:{"job-creation-success":_vm.notifyJobCreationSuccess,"job-creation-fail":_vm.notifyJobCreationFail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }