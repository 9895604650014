<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="formDialog"
        max-width="900px"
      >
        <v-container style="background: white;">
          <v-form ref="form">
            <h1
              class="pt-6"
            >
              {{ titleProp }}
            </h1>
            <v-date-picker
              full-width
              v-model="physicalCount.date"
            />
            <v-text-field
              v-model="physicalCount.name"
              :rules="[(v) => !!v || 'Name is required']"
              required
            >
              <template #label>
                Name<RedAsterisk />
              </template>
            </v-text-field>
          </v-form>
          <v-btn
            class="mr-4"
            @click="submit"
            color="primary"
            x-large
          >
            submit
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            outlined
            x-large
            @click="closeForm"
          >
            cancel
          </v-btn>
        </v-container>
      </v-dialog>
    </v-row>
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import PhysicalCountListApi from '@/api/PhysicalCountListApi';
export default {
    props:
    {
        physicalCountProp: {
            type: Object,
            default: () => {}
        },
        titleProp: {
            type: String,
            default: ''
        }

    },
    watch: {
    physicalCountProp: {
      immediate: true,
      handler(value) {

        this.physicalCount = value || 
        {
            id: 0,
          name: '',
          date: new Date().toISOString().substr(0, 10),
          created_by: this.$store.getters.user.id
        }
      }
    },
  },
    data: () => ({
        formDialog: false,
        physicalCount: null
        
    }),
     created() {
        this.initialize();
    },
    methods: {
        initialize() {
          
          
        },

        async submit(){

            if(!await this.$refs.form.validate()) return
            var data = {
                id: this.physicalCount.id,
                name: this.physicalCount.name,
                date: this.physicalCount.date,
                created_by: this.physicalCount.created_by,
            }
            var response = await PhysicalCountListApi.store(data)
            if(!response){
                this.$refs.snackbar.showError()
                return
            }

            this.physicalCount.id !== 0 ? this.$emit('list-form-success', 'Physical Count Updated') : this.$emit('list-form-success', 'Physical Count Created')
            this.closeForm()
            
        },

        openPhysicalCountForm(){
            this.formDialog = true
        },
        closeForm(){
            this.reset()
            this.formDialog = false
            
        },
        
        reset(){
            this.physicalCount = 
            {
                id: 0,
                name: '',
                date: new Date().toISOString().substr(0, 10),
                created_by: this.$store.getters.user.id
            }
        }
    }

}


</script>

<style lang="scss" scoped>
::v-deep .v-date-picker-table .v-btn{
    font-size: 20px;
}

::v-deep .v-date-picker-table--date .v-btn{
    height: 40px;
    width: 40px;
}

::v-deep .v-date-picker-header__value button {
    font-size: 30px;
}
</style>