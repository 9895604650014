var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[_c('v-btn',{staticClass:"d-flex align-self-end",attrs:{"color":"primary","outlined":""},on:{"click":function () {
            this$1.$refs.equipmentItemForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new equipment ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.itemsHeaders,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":20,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.manufacturer",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturer)+" ")])]}},{key:"item.model",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.model)+" ")])]}},{key:"item.serial_number",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.serial_number ? item.serial_number : '-')+" ")])]}},{key:"item.item_reference",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.item_reference ? item.item_reference : '-')+" ")])]}},{key:"item.updated_by",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_by)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openItemForm(item)}}},[_vm._v(" EDIT ")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.openItemForm(item, true)}}},[_vm._v(" CLONE ")]),_c('a',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1),_c('Snackbar',{ref:"snackbar"}),_c('EquipmentItemForm',{ref:"equipmentItemForm",on:{"item-creation-success":_vm.notifyItemCreationSuccess,"item-creation-fail":_vm.notifyItemCreationFail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }