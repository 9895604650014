export const auth_request = (state) => {
  state.status = 'loading';
};

export const auth_success = (state, data) => {
  state.user = data.user;
  state.token = data.token;
};

// export const set_client_env = (state, clientEnv) => {
//   state.clientEnv = clientEnv;
// };


export const logout = (state) => {
  state.token = '';
};

export const storeBom = (state, bomId) => {
  state.selected_bom = bomId;
};

export const storeRma = (state, rmaId) => {
  state.selected_rma = rmaId;
};

export const storeJobsTab = (state, tab) => {
  state.selected_jobs_tab = tab;
};

export const storeInventoryTab = (state, tab) => {
  state.selected_inventory_tab = tab;
};

export const storeEquipmentTab = (state, tab) => {
  state.selected_equipment_tab = tab;
};

export const storeEquipmentList = (state, list) => {
  state.equipment_list = list;
};

export const storeJobsList = (state, list) => {
  state.jobs_list = list;
};

export const storeConditionsList = (state, list) => {
  state.conditions_list = list;
};

export const openEquipmentMovementForm = (state, type) => {
  state.equipment_movement_form_open = true;
  state.equipment_movement_form_type = type;
};

export const closeEquipmentMovementForm = (state) => {
  state.equipment_movement_form_open = false;
  state.equipment_movement_form_type = null;
};

export const storeUpdateExcelFile = (state, file) => {
  state.excel_file = file;
};

export const storeBusinessRules = (state, rules) => {
  state.business_rules = rules;
}

export const storeActivatedPhysicalCount = (state, physicalCount) => {
  state.physicalCount = physicalCount;
}

export default {
  auth_request,
  auth_success,
  logout,
  storeBom,
  storeRma,
  storeJobsTab,
  storeInventoryTab,
  storeEquipmentTab,
  storeEquipmentList,
  storeJobsList,
  storeConditionsList,
  openEquipmentMovementForm,
  closeEquipmentMovementForm,
  storeUpdateExcelFile,
  storeBusinessRules,
  storeActivatedPhysicalCount
};
