<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="600px"
        :persistent="true"
      >
        <validation-observer
          ref="userValidationObserver"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.first_name"
                        :error-messages="errors"
                        outlined
                      >
                        <template slot="label">
                          First Name<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.last_name"
                        :error-messages="errors"
                        outlined
                      >
                        <template slot="label">
                          Last Name<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Roles"
                      rules="required|list"
                    >
                      <v-autocomplete
                        :items="roles"
                        v-model="user.roles"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        multiple
                        return-object
                        outlined
                      >
                        <template slot="label">
                          Roles<RedAsterisk />
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone Number"
                    >
                      <VuePhoneNumberInput
                        v-model="user.phone_number"
                        :error-messages="errors"
                        @update="onUpdate"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Username"
                      :rules="user.id ? '': 'required|username'"
                    >
                      <v-text-field
                        v-model="user.username"
                        :error-messages="errors"
                        outlined
                      >
                        <template slot="label">
                          Username<RedAsterisk />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="email"
                    >
                      <v-text-field
                        v-model="user.email"
                        type="email"
                        :error-messages="errors"
                        outlined
                      >
                        <template slot="label">
                          Email
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      :rules="user.id ? '' : 'required|password:@confirm'"
                    >
                      <v-text-field
                        v-model="user.password"
                        type="password"
                        :error-messages="errors"
                        autocomplete="new-password"
                        outlined
                      >
                        <template slot="label">
                          Password<RedAsterisk v-if="!user.id" />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="confirm"
                      :rules="user.id ? '' : 'required'"
                    >
                      <v-text-field
                        v-model="user.confirmPassword"
                        type="password"
                        :error-messages="errors"
                        outlined
                      >
                        <template slot="label">
                          Confirm Password<RedAsterisk v-if="!user.id" />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="normal"
                class="mr-2"
                @click="closeForm"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="submitForm"
              >
                {{ user.id !== 0 ? 'Update' : 'Create' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
        <Snackbar ref="snackbar" />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import RolesApi from '../../api/RolesApi';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { required, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import UsersApi from '@/api/UsersApi'
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

extend('username', {
  async validate (value) {
    return UsersApi.checkUsername(value)
     
  },
  message: 'Email is already registered. Please, try another one.'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('list', {
  async validate (value) {
    return value.length > 0
     
  },
  message: '{_field_} can not be empty'
})


export default {
  components: {
    VuePhoneNumberInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userP: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    userP: {
      immediate: true,
      handler(value) {
        this.user = value || this.defaultUser
      }
    },
  },
  data: () => ({
    dialog: false,
    phvalid: false,
    roles: [],
    user: null,
    defaultUser:  
    {
      id: 0,
      first_name: '',
      username: '',
      last_name: '',
      email: '',
      roles: [],
      phone_number: '',
      password: null,
      confirmPassword: null,
    }
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchRoles();
    },
    openDialog() {
      this.dialog = true;
    },
    closeForm(){
      this.dialog = false;
      this.clearForm()
    },
    onUpdate(payload) {
      this.phvalid = payload.isValid;
    },

    async submitForm(){
      if(!await this.$refs.userValidationObserver.validate()) return
      if(!this.phvalid){
        this.$refs.snackbar.showError('Please Enter Valid Phone number')
        return
      }
      var response = await UsersApi.store(this.user)

      if(!response){
        this.$refs.snackbar.showError()
        return
      }

      this.user.id !== 0 ? this.$refs.snackbar.showSuccess('User Updated') : this.$refs.snackbar.showSuccess('User Created')
      this.$emit('user-creation');
      this.closeForm()
    },
    async clearForm() {
      await this.$refs.userValidationObserver.reset();
      this.user = this.defaultUser
    },
    async fetchRoles() {
      this.roles = await RolesApi.getList();
    },
  },
};
</script>
<style>
  .v-input--checkbox .v-label {
    font-size: 0.9em;
  }
</style>
