<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="d-flex justify-end pr-6">
        <v-autocomplete
          v-model="jobFilter"
          :items="jobs"
          item-text="name"
          item-value="id"
          hide-details
          @change="fetchBoms"
          placeholder="Filter by Job"
          :clearable="true"
        >
          <template v-slot:selection="{ item }">
            <span class="black--text"
              >JOB #{{ item.code }} ({{ item.address_simple }})</span
            >
          </template>
          <template v-slot:item="{ item }">
            <span class="black--text"
              >JOB #{{ item.code }} ({{ item.address_simple }})</span
            >
          </template>
        </v-autocomplete>
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end ml-4"
          @click="
            () => {
              this.$refs.bomForm.openDialog(this.type);
            }
          "
          v-if="canEdit"
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
          new {{ type }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="bomsHeaders"
          :items="boms"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
                 <template v-slot:[`item.worker`]="{ item }">
                {{ item.worker != ' ' ? item.worker : '-'}} 
                </template>
                
          <template v-slot:[`item.actions`]="{ item }">
            <a v-if="item.status === 'Released'" @click="showBom(item)">
              VIEW
            </a>
            <template v-else>
              <a class="mr-2" @click="deleteBom(item)"> DELETE </a>
              <a @click="showBom(item)"> EDIT </a>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <BomForm ref="bomForm" v-on:bom-creation="notifyBomCreated"></BomForm>
  </v-container>
</template>

<script>
import BomForm from '../../components/forms/BomForm';
import BomRmasApi from '../../api/BomRmasApi';
import JobsApi from '../../api/JobsApi';
import Bus from '../../bus';

export default {
  name: 'Boms',
  components: {
    BomForm,
  },
  data: () => ({
    type: 'bom',
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    bomsHeaders: [
      { text: 'Bom Code', value: 'code' },
      { text: 'Address', value: 'job_address' },
      { text: 'Worker', value: 'worker' },
      { text: 'Status', value: 'status' },
      { text: 'Total Items', value: 'items_num' },
      { text: 'Total Quantity', value: 'items_qty' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    boms: [],
    jobFilter: null,
    jobs: [],
    canEdit: false,
  }),
  filters: {},
  created() {
    this.canEdit = this.$store.getters.userCanEdit;
    //this.initialize();
  },
  methods: {
    async initialize(type, jobId = null) {
      this.type = type;
      this.jobFilter = jobId;
      if (this.type === 'bom') {
        this.bomsHeaders[0].text = 'BOM Code';
      } else {
        this.bomsHeaders[0].text = 'RMA Code';
      }

      await Promise.all([this.fetchBoms(), this.fetchJobs()]);
    },
    openBomForm(item) {
      this.$refs.bomForm.openDialog(this.type, item);
    },
    showBom(item) {
      const bom = {
        id: item.id,
        code: item.code,
        status: item.status,
        worker: item.worker,
      };
      this.$emit('open-bom', bom, this.type);
    },
    async deleteBom(bom) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await BomRmasApi.softDelete(bom.id);
      if (res.err) {
        if (res.errDesc === 'bom_released') {
          Bus.$emit('alert', 'error', 'Error!');
        }
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchBoms();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    async notifyBomCreated(res) {
      // Redirect to BOM page and show new BOM
      await this.fetchBoms();
      const bom = this.boms.find((x) => x.id === res.id);
      this.showBom(bom);
    },
    async fetchBoms() {
      this.loading = true;
      // Cast potential undefined to null
      this.jobFilter = this.jobFilter || null;
      this.boms = await BomRmasApi.getList(this.type, this.jobFilter);
      this.loading = false;
    },
    async fetchJobs() {
      this.jobs = await JobsApi.getList();
    },
  },
};
</script>
<style scoped></style>
