<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900px" :persistent="true">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="errorAlert" type="error">
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <p>Sheet Date</p>
                  <v-date-picker full-width v-model="picker"></v-date-picker>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-autocomplete
                    v-model="job"
                    :items="jobs"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Job is required']"
                    required
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        {{ item.name }}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text" v-if="item.name">
                        {{ item.name }}
                      </span>
                      <!-- <span class="black--text" v-else>
                        {{ item.supervisor }}
                      </span> -->
                    </template>
                    <template slot="label">
                      Job<RedAsterisk></RedAsterisk>
                    </template>
                  </v-autocomplete>

                  <v-text-field v-model="release_floor" label="Release/Floor">
                  </v-text-field>
                  <v-autocomplete
                    v-model="ex_color"
                    :items="colors"
                    item-text="code"
                    item-value="id"
                    required
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template slot="label"> External colour </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="in_color"
                    :items="colors"
                    item-text="code"
                    item-value="id"
                    required
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template slot="label"> Internal colour </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submitForm">
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import JobsApi from '../../api/JobsApi';
import ConsumptionsApi from '../../api/ConsumptionsApi';
import UsersApi from '../../api/UsersApi';
import ColorsApi from '../../api/ColorsApi';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    supervisors: [],
    jobs: [],
    colors: [],
    id: null,
    supervisor: null,
    release_floor: null,
    job: null,
    description: '',
    ex_color: '',
    in_color: '',
    ex: '',
    in: '',
    picker: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchJobs(), this.fetchForemen(), this.fetchPColors()]);
    },
    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit Supply to Line / Return Sheet #${item.id}`;
          this.btnSubmitName = 'Update';
          this.loadConsumptionToForm(item);
        } else {
          this.dialogTitle = 'New Supply to Line / Return Sheet';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        if (this.ex_color) {
          this.ex =
            this.ex_color.color_name + ' (' + this.ex_color.color_code + ')';
        } else {
          this.ex = '-';
        }
        if (this.in_color) {
          this.in =
            this.in_color.color_name + ' (' + this.in_color.color_code + ')';
        } else {
          this.in = '-';
        }
        this.userId = this.$store.getters.user.id;
        const data = {
          id: this.id,
          job_id: this.job || null,
          ex_color: this.ex_color
            ? this.ex_color.id
              ? this.ex_color.id
              : this.ex_color
            : null,
          in_color: this.in_color
            ? this.in_color.id
              ? this.in_color.id
              : this.in_color
            : null,
          supervisor_id: this.supervisor,
          sheet_date: this.picker,
          created_by: this.userId,
          release_floor: this.release_floor,
        };
        const res = await ConsumptionsApi.store(data);
        if (res) {
          this.$emit('consumption-creation-success');
        } else {
          this.$emit('consumption-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadConsumptionToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.job = item.job_id;
      this.supervisor = item.supervisor_id;
      this.ex_color = item.ex_color;
      this.in_color = item.in_color;
      this.picker = item.sheet_date;
      this.release_floor = item.release_floor;
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.job = null;
      this.supervisor = null;
      this.ex_color = null;
      this.in_color = null;
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchJobs() {
      this.jobs = await JobsApi.getList();
    },
    async fetchForemen() {
      this.supervisors = await UsersApi.getListForemen();
    },
    async fetchPColors() {
      const colorsList = await ColorsApi.getList();
      this.colors = colorsList.filter(
        (color) => color.enabled == 1 && color.deleted != 1
      );
    },
    // getJobText(item){
    //   return `Job ${ item.id } ${ item.address }`;
    // },
    getSupervisorText(item) {
      return `${item.first_name} ${item.last_name}`;
    },
  },
};
</script>
