<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="9"
        class="d-flex justify-end"
        v-if="canEdit"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.jobForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Job
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="jobsHeaders"
          :items="jobs"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #[`item.address`]="{ item }">
            {{ item.address ? item.address : '-' }}
          </template>
          <template #[`item.supervisor`]="{ item }">
            {{ item.supervisor ? item.supervisor : '-' }}
          </template>
          <template #[`item.actions`]="{ item }">
            <a
              v-if="enabled_bom_rma == 1"
              class="mr-2"
              @click="showBoms(item, 'bom')"
            > BOMs </a>
            <a
              v-if="enabled_bom_rma == 1"
              class="mr-2"
              @click="showBoms(item, 'rma')"
            > RMAs </a>
            <a
              @click="openJobForm(item)"
              class="mr-2"
              v-if="canEdit"
            > EDIT </a>
            <a
              @click="deleteJob(item)"
              class="mr-2"
            > DELETE </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <JobForm
      ref="jobForm"
      @job-creation-success="notifyJobCreationSuccess"
      @job-creation-fail="notifyJobCreationFail"
    />
  </v-container>
</template>

<script>
import Snackbar from '../../components/Snackbar';
import JobForm from '../../components/forms/JobForm';
import JobsApi from '../../api/JobsApi';

export default {
  name: 'Jobs',
  components: {
    Snackbar,
    JobForm,
  },
  data: () => ({
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    jobsHeaders: [
      { text: 'Job #', value: 'code' },
      { text: 'Job name', value: 'name' },
      { text: 'Client Name', value: 'client_name'},
      { text: 'Address', value: 'address' },
      { text: 'Project Manager', value: 'supervisor' },
      // { text: 'Total BOMs', value: 'total_boms' },
      // { text: 'Total RMAs', value: 'total_rmas' },
      { text: 'Created At', value: 'created_at' },
       { text: 'Created By', value: 'created_by' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', value: 'actions', width: '12%', align: 'end' },
    ],
    jobs: [],
    canEdit: false,
    enabled_bom_rma: 0
  }),
  filters: {},
  created() {
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;
    var businessRules = this.$store.getters.businessRules;
    this.enabled_bom_rma = businessRules.find(x => x.name === 'Bom/rma code').value;
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchJobs()]);
    },
    openJobForm(item) {
      this.$refs.jobForm.openDialog(item);
    },
    async deleteJob(item){
      if (!confirm('Are you sure you want to delete this Job?')) {
        return;
      }
      await JobsApi.softDelete(item.id);
      this.$refs.snackbar.showSuccess('Success!');
      await this.fetchJobs();
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyJobCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchJobs();
    },
    notifyJobCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchJobs() {
      this.loading = true;
      this.jobs = await JobsApi.getList();
      this.loading = false;
    },
    openConsumption(item) {
      this.$router.push(`/consumption/${item.id}`);
    }
  },
};
</script>
<style scoped></style>
