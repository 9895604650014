<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="9" class="d-flex justify-end">
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.clientForm.openDialog();
            }
          "
          v-if="canEdit"
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
          new client
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="clients"
          :items-per-page="15"
          :search="search"
          dense
        >
        <template v-slot:[`item.name`]="{ item }">
            <text-highlight :queries="search">
              {{ item.name ? item.name : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <text-highlight :queries="search">
              {{ item.address }}
            </text-highlight>
          </template>
          <template v-slot:[`item.city`]="{ item }">
            <text-highlight :queries="search">
              {{ item.city }}
            </text-highlight>
          </template>
          <template v-slot:[`item.region.name`]="{ item }">
            <text-highlight :queries="search">
              {{ item.region.name }}
            </text-highlight>
          </template>
          <template v-slot:[`item.postal_code`]="{ item }">
            <text-highlight :queries="search">
              {{ item.postal_code ? item.postal_code : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.country.name`]="{ item }">
            <text-highlight :queries="search">
              {{ item.country.name }}
            </text-highlight>
          </template>
          <template v-slot:[`item.phone_number`]="{ item }">
            <text-highlight :queries="search">
              {{ item.phone_number ? item.phone_number : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-if="canEdit">
            <a class="mr-2" @click="deleteClient(item)"> DELETE </a>
            <a @click="openClientForm(item)"> EDIT </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar"></Snackbar>
    <ClientForm
      ref="clientForm"
      v-on:client-creation-success="notifyClientCreationSuccess"
      v-on:client-creation-fail="notifyClientCreationFail"
    ></ClientForm>
  </v-container>
</template>

<script>
import axios from 'axios';
import Snackbar from '../components/Snackbar.vue';
import Bus from '../bus';
import ClientForm from '../components/forms/ClientForm.vue';
import ClientsApi from '../api/ClientsApi';
import TextHighlight from 'vue-text-highlight';

export default {
  name: 'Clients',
  components: {
    'text-highlight': TextHighlight,
    Snackbar,
    ClientForm,
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Street Address', value: 'address' },
      { text: 'City', value: 'city' },
      { text: 'Province/State', value: 'region.name' },
      { text: 'Postal Code', value: 'postal_code' },
      { text: 'Country', value: 'country.name' },
      { text: 'Phone Number', value: 'phone_number' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    clients: [],
    search: '',
    canEdit: false,
  }),
  filters: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.canEdit = this.$store.getters.userCanEdit;
      await this.fetchClients();
    },
    async fetchClients() {
      let clients = await axios.get('/clients');
      if (clients.status === 200) {
        let clientsAddress = clients.data;
        clientsAddress.forEach((el) => {
          el.address = this.renderAddress(el);
        });
        this.clients = clientsAddress;
      } else {
        console.error('Could not fetch clients');
      }
    },
    renderAddress(item) {
      let address = '';
      address += `${item.building} ${item.street}`;
      if (item.unit) {
        address += `, Unit ${item.unit}`;
      }
      return address;
    },
    openClientForm(item = null) {
      this.$refs.clientForm.openDialog(item);
    },
    async deleteClient(client) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await ClientsApi.softDelete(client.id);
      if (res.err) {
        Bus.$emit('alert', 'error', '');
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchClients();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    notifyClientCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchClients();
    },
    notifyClientCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
  },
};
</script>
<style scoped></style>
