<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900px" :persistent="true">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="errorAlert" type="error">
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col class="pb-0">
                   <v-autocomplete
                    v-model="category"
                    :items="categories"
                    :item-text="getRawMaterialText"
                    item-value="item"
                    
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item }}
                        </p>
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text" >
                        <p class="profile-p">
                          {{ item }}
                        </p>
                      </span>
                    </template>
                    <template slot="label">
                      Category
                    </template>
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="consumption"
                    :items="computedInventoryitems"
                    :item-text="getRawMaterialText"
                    item-value="item"
                    :rules="[(v) => !!v || 'Raw Material Consumption is required']"
                    required
                    return-object
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">{{ item.material_id }}: {{ item.name }}</p>
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text" v-if="item.address">
                        <p class="profile-p">{{ item.material_id }}: {{ item.name }}</p>
                      </span>
                      <span class="black--text" v-else>
                        <p class="profile-p">{{ item.material_id }}: {{ item.name }}</p>
                      </span>
                    </template>
                    <template slot="label">
                          Raw Material<RedAsterisk></RedAsterisk>
                    </template>
                  </v-autocomplete>
                  <v-text-field v-model="quantity_supplied" :rules="[(v) => !!v || 'Quantity supplied is required']">
                      <template slot="label">
                          Quantity supplied<RedAsterisk></RedAsterisk>
                      </template>
                  </v-text-field>
                  <v-text-field v-model="quantity_missed" :rules="[(v) => v == '0' || !!v || 'Quantity misssed is required']">
                      <template slot="label">
                          Quantity misssed<RedAsterisk></RedAsterisk>
                      </template>
                  </v-text-field>
                  <v-combobox 
                    v-model="inventoryTag"
                    :items="inventoryTags"
                    item-text="inventory_tag"
                    text-val="inventoryTag"
                    :return-object="true"
                    @change="inventoryTagOnChange"
                    :rules="[(v) => !!v || 'Inventory tag is required']"
                    required
                  >
                    <template slot="label">
                      Inventory tag<RedAsterisk></RedAsterisk>
                    </template>
                   
                  </v-combobox >
                  <!-- <v-autocomplete
                    v-model="inventory_tag"
                    :items="computedInventoryTags"
                    item-text="inventory_tag"
                    item-value="item"
                    :rules="[(v) => !!v || 'Inventory tag is required']"
                    required
                  >
                    <template v-slot:selection="{ item }">
                      <span class="black--text">
                        <p class="profile-p">
                          {{ item.inventory_tag }}
                        </p>
                      </span>
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="black--text" >
                        <p class="profile-p">
                          {{ item.inventory_tag }}
                        </p>
                      </span>
                    </template>
                    <template slot="label">
                      Inventory tag<RedAsterisk></RedAsterisk>
                    </template>
                  </v-autocomplete> -->
                  </v-col>
                <v-col cols="6" v-if="consumption"  max-width="400px" class="pb-0">
                  <p v-if="consumption">Current Raw Material Image</p>
                    <v-img :src="convertedPhoto(this.consumption.photo, this.consumption.mime_type)" v-if="consumption" contain height="100%" width="100%"></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submitForm">
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../../components/RedAsterisk';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import RecordsApi from '../../api/RecordsApi';
import UsersApi from '../../api/UsersApi';
import InventoryTagsApi from '../../api/InventoryTagsApi';

export default {
  components: {
    RedAsterisk,
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    supervisors: [],
    consumptions: [],
    id: null,
    supervisor: null,
    consumption: null,
    Profiles_id: null,
    quantity_supplied: '',
    quantity_missed: '',
    die: '',
    photo: null,
    description: '',
    code: null,
    isIdParamActive: false,
    param_id: null,
    inventoryTag: null,
    item: {},
    category: null,
    categories: [],
    inventoryTags: [],
    color: null,
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
     
  },
  computed: {
    computedInventoryitems(){

      const itemsColorFiltered = this.color != null 
      ? this.color.ex_color != null || this.color.in_color != null
      ? this.consumptions.filter(item => {

          if(item.colors_ids){
            const color_ids_array = item.colors_ids.split(',');
            const result = color_ids_array.filter(color => color == this.color.ex_color || color == this.color.in_color ? true : false);
            return result.length > 0;
          }
          return false;
        }
      ) 
      : this.consumptions 
      : this.consumptions;

      const itemsList = this.category != null ? itemsColorFiltered.filter(item => item.category === this.category) : itemsColorFiltered;

      //return itemsList.sort((a,b) => a.material_id.toUpperCase().localeCompare(b.material_id.toUpperCase()));
      return itemsList.sort((a,b) => {

        if(a.material_id != null) return a.material_id.toUpperCase().localeCompare(b.material_id.toUpperCase())
      });

    },
    computedInventoryTags(){
      
      return this.consumption != null ? this.inventoryTags.filter(item => item.inventory_item_id === this.consumption.id && (item.color_ids == this.color.ex_color || item.color_ids == this.color.in_color)) : this.inventoryTags;

    },
  },
  created() {
    this.initialize();
    if(this.$route.params.id) {
      this.param_id = this.$route.params.id;
      this.isIdParamActive = true;
    }
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchConsumptions(), this.fetchInventoryTags()]);
    },
    openDialog(item = null, color = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          
          this.dialogTitle = `Edit Record #${item.id}`;
          this.btnSubmitName = 'Update';
          this.color = color
          this.loadProfileToForm(item);
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        let data = new FormData();
        if (this.consumption) {
          data.append('consumption_id', this.$route.params.id);
          data.append('inventory_item_id', this.consumption.id);
        }
        
        if(this.id) {
          data.append('id', this.id);
        }

        if (this.quantity_supplied) {
          data.append('quantity_supplied', this.quantity_supplied);
        }


        if (this.quantity_missed) {
          data.append('quantity_missed', this.quantity_missed);
        }
        if (this.inventoryTag) {
          data.append('inventory_tag', this.inventoryTag);
        }
        

        const res = await RecordsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadProfileToForm(item) {
      
      await this.$refs.form.reset();
      this.id = item.id;
      this.consumption_photo = item.inventoryItem;
      this.quantity_supplied = item.quantity_supplied;
      this.quantity_missed = item.quantity_missed;
      this.die = item.die;
      this.description = item.description;
      this.photo = item.photo;
      this.consumption = this.consumptions.find(cons => cons.id == item.inventory_item_id);
      this.inventoryTag = item.inventory_tag;
      this.item = item;
      this.category = this.consumptions.find(it => it.id == item.inventory_item_id).category;
      
      
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    async fetchConsumptions() {
      this.consumptions = await InventoryItemsApi.getList();
      this.categories = [...new Set(this.consumptions.map(item => item.category))]
    },
    async fetchInventoryTags(){
      this.inventoryTags = await InventoryTagsApi.getList();
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    getRawMaterialText(item){
      return `${ item.material_id } ${ item.name }`;
    },
    inventoryTagOnChange(val){
      this.inventoryTag = val != null && val.inventory_tag ? val.inventory_tag : val;
    }
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p{
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}</style>