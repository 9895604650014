<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" :persistent="formPersistent">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="formAlert" :type="formAlertType">
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="6" class="pb-0">
                  <v-autocomplete
                    v-model="category"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Category is required']"
                    required
                  >
                    <template slot="label">
                      Category<RedAsterisk></RedAsterisk>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('category')"
                      >
                        <v-icon small>fas fa-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-autocomplete
                    v-model="manufacturer"
                    :items="manufacturersList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Manufacturer is required']"
                  >
                    <template slot="label">
                      Manufacturer<RedAsterisk></RedAsterisk>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn
                        class="mrp-btn-add"
                        color="primary"
                        outlined
                        small
                        @click="addProperty('manufacturer')"
                      >
                        <v-icon small>fas fa-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="model"
                    :rules="[(v) => !!v || 'Model is required']"
                    required
                  >
                    <template slot="label">
                      Model<RedAsterisk></RedAsterisk>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="serialNumber"
                    label="Serial number"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="itemReference"
                    label="Item reference"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="warehouseShelf"
                    item-text="name"
                    :rules="[(v) => !!v || 'Shelf is required']"
                    item-value="id"
                    :clearable="true"
                  >
                    <template slot="label">
                      Shelf in the warehouse<RedAsterisk></RedAsterisk>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-file-input
                    v-model="photo"
                    accept="image/*"
                    label="Photo"
                    item-text="name"
                    item-value="id"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <span v-if="isEdit" class="caption secondary--text pl-2">
              Updated at {{ updatedDate }}
            </span>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submitForm">
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '../RedAsterisk';
import EquipmentItemsApi from '../../api/EquipmentItemsApi';
import EquipmentCategoriesApi from '../../api/EquipmentCategoriesApi';
import ManufacturersApi from '../../api/ManufacturersApi';
import Compressor from 'compressorjs';

export default {
  components: {
    RedAsterisk,
  },

  data: () => ({
    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: '',
    formPersistent: true,
    isEdit: false,
    // Equipment
    categories: [],
    manufacturersList: [],
    // Equipment Item
    id: null,
    category: null,
    manufacturer: null,
    model: null,
    serialNumber: null,
    warehouseShelf: null,
    itemReference: null,
    photo: null,
    updatedDate: null,
  }),

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      Promise.all([this.fetchCategories(), this.fetchManufacturers()]);
    },

    async openDialog(item = null, isClone = false) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        if (item && isClone === false) {
          this.dialogTitle = `Edit Equipment #${item.code}`;
          this.btnSubmitName = 'Update';
          this.isEdit = true;
          this.loadItemToForm(item);
        } else if (item && isClone) {
          item.id = null;
          this.dialogTitle = `Clone Equipment #${item.code}`;
          this.btnSubmitName = 'Create';
          this.isEdit = false;
          await this.loadItemToForm(item);
          this.serialNumber = null;
        } else {
          this.dialogTitle = 'New Item';
          this.btnSubmitName = 'Create';
          this.isEdit = false;
          this.clearForm();
        }
      });
    },

    async submitForm() {
      if (this.$refs.form.validate()) {
        const username = this.$store.getters.user.username;
        const userId = this.$store.getters.user.id;

        let data = new FormData();

        if (this.id) {
          data.append('id', this.id);
        }

        if (this.serialNumber) {
          data.append('serial_number', this.serialNumber);
        }

        if (this.itemReference) {
          data.append('item_reference', this.itemReference);
        }

        if (this.warehouseShelf) {
          data.append('warehouse_shelf', this.warehouseShelf);
        }
        
        let image = await this.compressPhoto(this.photo).then((res) => {
          data.append('photo', res, res.name);
        });

        data.append('equipment_category_id', this.category);
        data.append('manufacturer_id', this.manufacturer);
        data.append('model', this.model);
        data.append('updated_by', userId);
        data.append('user_id', userId);

        const res = await EquipmentItemsApi.storeFormData(data);

        const equipmentList = await EquipmentItemsApi.getList();
        equipmentList.map((x) => {
          x.name = `Equipment #${x.code}: ${x.category} / ${x.manufacturer} / ${
            x.model
          } / ${x.serial_number ? x.serial_number : '-'}`;
        });
        this.$store.commit('storeEquipmentList', equipmentList);

        if (res) {
          this.$emit('item-creation-success');
        } else {
          this.$emit('item-creation-fail');
        }
        this.dialog = false;
      }
    },
    async compressPhoto(photo) {
      return new Promise((resolve, reject) => {
        if (!photo) reject('rejected!');

        return new Compressor(photo, {
          quality: 0.2,
          async success(result) {
            resolve(result);
          },
          error(err) {
            reject(null)
            console.log(err.message);
          },
        });
      });
    },
    async addProperty(prop) {
      const message = `Please enter new ${prop}`;
      let propVal = prompt(message);

      if (!propVal || propVal === '') {
        return false;
      } else {
        propVal = propVal.trim();
      }

      let res = false;
      let exists = false;
      let errorText = '';
      const data = { name: propVal };

      switch (prop) {
        case 'category':
          await this.fetchCategories();
          exists = this.categories.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase()
          );
          if (exists != -1) {
            errorText = 'Error! Category already exists.';
          } else {
            res = await EquipmentCategoriesApi.store(data);
            if (res) {
              await this.fetchCategories();
              this.category = this.categories.find(
                (x) => x.name === propVal
              ).id;
            }
          }
          break;
        case 'manufacturer':
          await this.fetchManufacturers();
          exists = this.manufacturersList.findIndex(
            (x) => x.name.toLowerCase() === propVal.toLowerCase()
          );
          if (exists != -1) {
            errorText = 'Error! Manufacturer already exists.';
          } else {
            res = await ManufacturersApi.store(data);
            if (res) {
              await this.fetchManufacturers();
              this.manufacturer = this.manufacturersList.find(
                (x) => x.name === propVal
              ).id;
            }
          }
          break;
        default:
          break;
      }

      if (errorText !== '') {
        this.showFormAlert(errorText, 'error');
      } else if (res == true) {
        this.showFormAlert('Success!', 'success');
      }
    },

    showFormAlert(message, type) {
      this.formAlert = true;
      this.formAlertText = message;
      this.formAlertType = type;
      setTimeout(() => {
        this.formAlert = false;
      }, 3000);
    },

    async loadItemToForm(item) {
      await this.$refs.form.reset();
      this.updatedDate = item.updated_at;
      this.id = item.id;
      this.category = item.category_id;
      this.manufacturer = item.manufacturer_id;
      this.model = item.model;
      this.serialNumber = item.serial_number;
      // this.itemReference = item.item_reference;
      // this.warehouseShelf = item.warehouse_shelf;
    },

    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.category = null;
      this.manufacturer = null;
      this.model = null;
      this.serialNumber = '';
      this.itemReference = null;
      this.warehouseShelf = null;
      this.photo = null;
    },

    async fetchCategories() {
      this.categories = await EquipmentCategoriesApi.getList();
    },

    async fetchManufacturers() {
      this.manufacturersList = await ManufacturersApi.getList();
    },
  },
};
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
</style>
