import Api from './Api';

class PhysicalCountListApi extends Api {
  constructor() {
    super('physical_count_lists', 'physical_count_lists');
  }

  async activate(id){
    return await this.GetByUrl(`activate/${id}`)
  }
  async getActive(){
    return await this.GetByUrl('active')
  }

  async delete(id){
    return await this.softDelete(id)
  }
}
const physicalCountListApi = new PhysicalCountListApi();

export default physicalCountListApi;