<template>
  <v-container fluid class="px-4">
    <v-tabs v-model="selectedTab" @change="tabChanged">
      <v-tab> Jobs </v-tab>
      <v-tab v-if="enabled_bom_rma == 1"> BOMs </v-tab>
      <v-tab v-if="enabled_bom_rma == 1"> BOM {{ bomCode }} </v-tab>
      <v-tab v-if="enabled_bom_rma == 1"> RMAs </v-tab>
      <v-tab v-if="enabled_bom_rma == 1"> RMA {{ rmaCode }} </v-tab>
    </v-tabs>
    <Jobs v-if="selectedTab === 0" v-on:show-boms="showBoms"></Jobs>
    <Boms
      v-if="selectedTab === 1 || selectedTab === 3"
      ref="boms"
      v-on:open-bom="openBom"
    ></Boms>
    <Bom
      v-if="selectedTab === 2 || selectedTab === 4"
      ref="bom"
      v-on:change-bom="changeBom"
    ></Bom>
  </v-container>
</template>

<script>
import Jobs from './Jobs';
import Boms from './Boms';
import Bom from './Bom';

export default {
  name: 'Index',
  components: {
    Jobs,
    Boms,
    Bom,
  },
  data: () => ({
    selectedTab: 0,
    bomCode: '',
    rmaCode: '',
    enabled_bom_rma: 0
  }),
  filters: {},
  created() {
    this.initialize();
    var businessRules = this.$store.getters.businessRules;
    this.enabled_bom_rma = businessRules.find(x => x.name === 'Bom/rma code').value;
  },
  methods: {
    async initialize() {
      const selectedTab = this.$store.getters.selectedJobsTab;
      if (selectedTab) {
        this.selectedTab = selectedTab;
        this.tabChanged();
      }
      const selectedBom = this.$store.getters.selectedBom;
      if (selectedBom) {
        this.bomCode = selectedBom.code;
      }
      const selectedRma = this.$store.getters.selectedRma;
      if (selectedRma) {
        this.rmaCode = selectedRma.code;
      }
    },
    async openBom(bom, type) {
      if (type === 'bom') {
        this.selectedTab = 2;
        this.bomCode = bom.code;
        await this.tabChanged();
      } else {
        this.selectedTab = 4;
        this.rmaCode = bom.code;
        await this.tabChanged();
      }

      this.$nextTick(async () => {
        this.$refs.bom.renderBom(bom);
      });
    },
    async showBoms(jobId, type) {
      if (type === 'bom') {
        this.selectedTab = 1;
        await this.$nextTick();
        await this.$refs.boms.initialize('bom', jobId);
      } else {
        this.selectedTab = 3;
        await this.$nextTick();
        await this.$refs.boms.initialize('rma', jobId);
      }
      this.storeTabChanged();
    },
    changeBom(bom, type) {
      if (type === 'bom') {
        this.bomCode = bom.code;
      } else {
        this.rmaCode = bom.code;
      }
    },
    async tabChanged() {
      // Adjust child components
      await this.$nextTick();
      if (this.selectedTab === 1) {
        await this.$refs.boms.initialize('bom');
      } else if (this.selectedTab === 2) {
        await this.$refs.bom.initialize('bom');
      } else if (this.selectedTab === 3) {
        await this.$refs.boms.initialize('rma');
      } else if (this.selectedTab === 4) {
        await this.$refs.bom.initialize('rma');
      }
      this.storeTabChanged();
    },
    storeTabChanged() {
      // Keep current tab in local storage
      this.$store.commit('storeJobsTab', this.selectedTab);
    },
  },
};
</script>
<style scoped>
.v-tab {
  text-transform: none !important;
}
</style>
