<template>
  <v-main :class="{ colorMrpGrey: true }">
    <v-container>
      <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg3 :style="{ paddingTop: '15rem' }">
          <template>
            <v-row justify="center" class="mb-10">
              <v-img
                lazy-src="../assets/logo.png"
                max-height="150"
                max-width="200"
                src="../assets/logo.png"
              ></v-img
            ></v-row>
          </template>

          <v-card class="elevation-12">
            <v-form v-model="valid" @submit.prevent="login()" ref="form">
              <v-toolbar color="colorMrpRed" dark flat>
                <v-spacer />
                <v-toolbar-title
                  :style="{ fontSize: '2.1rem', userSelect: 'none' }"
                >
                  {{ appTitle }}
                  <span :style="{ fontSize: '1rem' }">v{{ appVersion }}</span>
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field
                  prepend-icon="fas fa-user"
                  name="username"
                  label="Username"
                  type="text"
                  :rules="[(v) => !!v || 'Username is required']"
                  v-model="username"
                  :color="'colorMrpGrey'"
                  required
                ></v-text-field>
                <v-text-field
                  prepend-icon="fas fa-lock"
                  id="password"
                  name="password"
                  type="password"
                  label="Password"
                  :rules="[(v) => !!v || 'Password is required']"
                  v-model="password"
                  :color="'colorMrpGrey'"
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions style="padding: 0px">
                <v-flex xs8 offset-xs1 v-if="submitted" class="pt-0">
                  <v-progress-linear
                    :indeterminate="true"
                    :color="'colorMrpGrey'"
                  ></v-progress-linear>
                </v-flex>
                <v-spacer></v-spacer>
                <v-card-title class="pt-0">
                  <v-btn type="submit" color="colorMrpRed" dark>Log in</v-btn>
                </v-card-title>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import Bus from '../bus';

export default {
  name: 'Login',
  components: {},
  data: () => ({
    valid: false,
    appTitle: 'ERP',
    username: '',
    password: '',
    submitted: false,
  }),
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.submitted = true;
        const username = this.username;
        const password = this.password;
        this.$store
          .dispatch('login', { username, password })
          .then(()=> {
          // var hostName = window.location.hostname;
          // var clientEnv = hostName.split(".")[0];

            // this.$store.dispatch('setClientEnv', {clientEnv})
          })
          .then(() => {
            this.submitted = false;
            this.$router.push('/');
          })
          .catch((err) => {
            this.submitted = false;
            Bus.$emit('alert', 'error', 'Login Failed!');
          });
      }
    },
  },
};
</script>
