<template>
  <div class="hello pa-6">
    <v-row>
      <v-col cols="3">
        <v-btn
          color="normal"
          class="mr-2"
          @click="changeCamera"
        >
          Switch Front/Back Camera
        </v-btn>
      </v-col>
      <v-col
        v-if="isPhysicalCount"
        cols="6"
        style="font-size: 30px;"
        align="center"
      >
        Scan QR Code (Physical Count)
      </v-col>
      <v-col
        v-else
        cols="6"
        style="font-size: 30px;"
        align="center"
      >
        Scan QR Code
      </v-col>
      <v-col cols="2" />
    </v-row>
    <v-row>
      <v-col align="center">
        Please place QR code into the green square
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3.5" />
      <v-col
        cols="5"
        align="center"
      >
        <qrcode-stream
          :camera="camera"
          @decode="processResult"
          @init="onInit"
          :track="paintBoundingBox"
        >
          <canvas
            class="overlay-line"
            id="square-canvas"
          />
        </qrcode-stream>
      </v-col>
      <v-col cols="3.5" />
    </v-row>
    <canvas
      style="z-index: 100;"
      id="myCanvas"
    />
    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Snackbar from '@/components/Snackbar';

export default {
  props: {
    msg: String,
    isPhysicalCount: {
      type: Boolean,
      default: false
    }
  },
  components: { QrcodeStream, Snackbar},
  data: () => ({
    loading: false,
    qrResult: '',
    scanCounter: 0,
    revisit: null,
    camera: 'rear',
    cameraState: '',
    visitorTypes: [],
    visitorTypeSelected: null,
    validationFailed: false,
    locationId: null,
    error: '',
    inventoryTags: [],
    selectedTag: null,
    openDialog: false,
    selectedQrCode: '',

  }),
  computed: {
    validationPending() {
      return this.camera === 'off';
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    error: function (message) {
      this.$refs.snackbar.showError(message);
    },
  },
  methods: {
    async initialize() {
      await this.$nextTick();
      this.turnCameraOn();

      var boxSizes = []
      boxSizes.push({boundingBox : {x: 50, y: 10, width: 200, height: 130 }})
      const canvas = document.getElementById('square-canvas');
      const ctx = canvas.getContext('2d');
      this.paintBoundingBox(boxSizes, ctx)
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device';
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)';
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?';
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser';
        }
      } finally {
        this.loading = false;
      }
    },

    turnCameraOn() {
      this.camera = 'rear'
    },

    turnCameraOff() {
      this.cameraState = this.camera
      this.camera = 'off';
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 1
        ctx.strokeStyle = '#5cb984'
        ctx.strokeRect(x, y, width, height)
      }
    },

    async processResult(result) {
      this.turnCameraOff();
      this.qrResult = result;
      const resultArray = result.split('/');
      var qrNumber = resultArray[4]
      this.getQrCode(qrNumber);
    },

    async getQrCode(qrCode) {
      if(!qrCode || qrCode === null) return

      this.isPhysicalCount 
      ? 
      this.$router.push(`/physical-count-qr/${qrCode}`) 
      :
      this.$router.push(`/inventory-qr/${qrCode}`)

    },
    changeCamera(){
      this.camera = this.camera == 'front' ? 'rear' : 'front'
    }
  },
};
</script>
<style scoped lang="scss">
.overlay-line {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
</style>