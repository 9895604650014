import Vue from 'vue';
import VueRouter from 'vue-router';
import JobsIndex from '../views/Jobs/Index';
import ConsumptionsIndex from '../views/Consumption/Index';
// import ConsumptionIndex from '../views/Consumption/ConsumptionRecord';
import SupplyToLineSheetIndex from '../views/SupplyToLineSheet/Index';
import Clients from '../views/Clients';
import AdminIndex from '../views/Admin/Index';
import Login from '../views/Login';
import store from '../store';
import InventoryQr from '@/views/InventoryQrs/InventoryQr.vue'
import InventoryQrItem from '@/views/InventoryQrs/InventoryQrItem.vue'
import PhysicalCountItem from '@/views/PhysicalCountItem'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/consumptions',
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/Inventory.vue'),
    meta: {
      title: 'Inventory',
    },
  },
  {
    path: '/inventory-qr',
    name: 'inventory-qr',
    component: InventoryQr,
    props: () => (
      { isPhysicalCount: false  }
    ),
    meta: {
      title: 'Inventory QR',
    },
  },
  {
    path: '/inventory-qr/:code',
    name: 'inventory-qr-item',
    component: InventoryQrItem,
    meta: {
      title: 'Inventory QR Code',
    },
  },

  {
    path: '/physical-count-qr',
    name: 'physical-count-qr',
    component: InventoryQr,
    props: () => (
      { isPhysicalCount: true  }
    ),
    meta: {
      title: '',
      physicalCount: true
    },
  },

  {
    path: '/physical-count-qr/:code',
    name: 'physical-count-qr',
    component: PhysicalCountItem,
    meta: {
      title: '',
      physicalCount: true
    },
  },

  {
    path: '/equipment',
    name: 'equipment',
    component: () =>
      import(/* webpackChunkName: "equipment" */ '@/views/Equipment.vue'),
    meta: {
      title: 'Equipment',
    },
  },
  {
    path: '/equipment/:id',
    name: 'equipmentItem',
    component: () =>
      import(
        /* webpackChunkName: "equipmentItem" */ '@/views/EquipmentItem.vue'
      ),
    meta: {
      title: 'Equipment Item',
    },
  },
  {
    path: '/consumptions',
    name: 'Consumptions',
    component: ConsumptionsIndex,
    meta: {
      title: 'Raw Materials Supply To Line / Return Sheets',
    },
  },
  // {
  //   path: '/consumption/:id',
  //   name: 'Consumption',
  //   component: ConsumptionIndex,
  //   meta: {
  //     title: 'Consumption',
  //   },
  // },
  {
    path: '/consumption/edit-sheet/:id',
    name: 'SupplyToLineSheet',
    component: SupplyToLineSheetIndex,
    meta: {
      title: 'Edit Supply to Line / Return Sheet',
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: JobsIndex,
    meta: {
      title: 'Jobs',
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: {
      title: 'Clients',
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminIndex,
    meta: {
      title: 'Admin',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/*',
    redirect: '/clients',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth == false)) {
    next();
  } else {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  }
});

export default router;
