<template class="formDialog">
  <v-row
    justify="center"
    style="font-size: 15px;"
  >
    <v-dialog
      v-model="dialog"
      max-width="1300px"
      :persistent="formPersistent"
    >
      <v-form
        v-model="valid"
        ref="form"
      >
        <v-card>
          <v-card-title>
            <span class="headline">New Inventory Tag</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="formAlert"
              :type="formAlertType"
            >
              {{ formAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="inventoryTag"
                    label="Inventory Tag"
                    
                    :rules="[(v) => !!v || 'Inventory Tag is required']"
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="inventoryItem"
                    label="Inventory Item"
                    :clearable="true"
                    :items="inventoryItemsList"
                    :item-text="
                      (item) => item.material_id + ': ' + item.name
                    "
                    item-value="id"
                    :rules="[(v) => !!v || 'Inventory Item is required']"
                    required
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="quantity"
                    label="Quantity"
                    :rules="[(v) => !!v || 'Quantity is required']"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="length"
                    label="Length"
                    :items="lengthsList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Length is required']"
                    required
                  />
                </v-col>
                
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="color"
                    label="Color"
                    :items="colorsList"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Color is required']"
                    required
                  >
                    <template #selection="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span class="black--text">
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="location"
                    label="Location"
                    :items="locationsList"
                    item-text="name"
                    item-value="id"
                    :clearable="true"
                    :rules="[(v) => !!v || 'Location is required']"
                    required
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="uom"
                    label="Units Of Measurement"
                    :items="uomsList"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Units Of Measurement is required']"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              large
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              large
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <Snackbar ref="snackbar" />
  </v-row>
</template>

<script>
import InventoryItemsApi from '../../api/InventoryItemsApi';
import ColorsApi from '../../api/ColorsApi';
import UomsApi from '../../api/UomsApi';
import LengthsApi from '../../api/LengthsApi';
import LocationsApi from '@/api/LocationsApi';
import Snackbar from '@/components/Snackbar';
import InventoryTagsApi from '@/api/InventoryTagsApi'


export default {
  components: {Snackbar},
  props: {
      enteredTag: String
  },
  data: () => ({
    // Form
    dialog: false,
    dialogTitle: '',
    valid: false,
    formAlert: false,
    formAlertText: '',
    formAlertType: 'error',
    btnSubmitName: 'ADD',
    formPersistent: true,
    // Inventory
    lengthsList: [],
    uomsList: [],
    colorsList: [],
    inventoryItemsList: [],
    locationsList: [],
    // Inventory Item
    uom: '',
    length: '',
    color: '',
    inventoryTag: '',
    inventoryItem: '',
    location: '',
    quantity: '',
    userId: null
  }),
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {

      Promise.all([
        this.fetchUoms(),
        this.fetchColors(),
        this.fetchLengths(),
        this.fetchInventoryItems(),
        this.fetchLocations()
      ]);
      this.userId = this.$store.getters.user.id;
      
    },
    openDialog() {
      this.dialog = true;
      this.inventoryTag = this.enteredTag
    },
    async fetchUoms() {
      this.uomsList = await UomsApi.getList();
    },
    async fetchColors() {
      const colorsList = await ColorsApi.getList();
      this.colorsList = colorsList.filter(color =>{
        if(color.deleted === 0 && color.enabled === 1)
          return color;
      })
    },
    async fetchLengths() {
      this.lengthsList = await LengthsApi.getList();
    },
    async fetchInventoryItems(){
        this.inventoryItemsList = await InventoryItemsApi.getList()
    },
    async fetchLocations(){
        this.locationsList = await LocationsApi.getList()
    },

     async submitForm() {
      if (this.$refs.form.validate()) {
        let data = new FormData();

        if (this.inventoryTag) {
          data.append('inventory_tag', this.inventoryTag);
        }

        if (this.inventoryItem) {
          data.append('inventory_item_id', this.inventoryItem);
        }

        if (this.uom) {
          data.append('uom_id', this.uom);
        }

        if (this.length) {
            data.append('length_id', this.length);
        }

        if (this.color) {
            data.append('color_id', this.color); 
        }

        if (this.location) {
            data.append('inventory_location_id', this.location);
        }
        
        if(this.quantity) {
            data.append('quantity', this.quantity);
        }
        if(this.userId){
            data.append('created_by', this.userId);
        }

        const res = await InventoryTagsApi.addTag(data)
        if (res === true) {
          //this.$refs.snackbar.showSuccess('Success!');
          this.dialog = false;
          this.$emit('submitQRCodeTag', this.inventoryTag)
        } else {
            this.$refs.snackbar.showError(res);
        }
      }
    },

  }
}
</script>

<style scoped>
.mrp-btn-add {
  min-width: 35px !important;
  padding: 0 !important;
}
.v-text-field >>> input {
    font-size: 1.5em !important;
}
.v-autocomplete >>> input{
    font-size: 1.5em !important;
}
.v-autocomplete >>> label{
    font-size: 1.3em !important;
}
.v-text-field >>> label {
    font-size: 1.3em !important;
}
.v-btn {
    font-size: 1.1em !important;
}
</style>
