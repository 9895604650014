export default {
  isLoggedIn: (state) => !!state.token,
  user: (state) => state.user,
  userToken: (state) => state.token,
  userCanEdit: (state) => {
    if(!state.user) return
    return state.user.roles.some(e => e.name === 'Admin' || e.name === 'Read-Write') ? true : false
  },
  appVersion: (state) => '2.9.0',
  selectedBom: (state) => state.selected_bom,
  selectedRma: (state) => state.selected_rma,
  selectedJobsTab: (state) => state.selected_jobs_tab,
  selectedInventoryTab: (state) => state.selected_inventory_tab,
  selectedEquipmentTab: (state) => state.selected_equipment_tab,
  equipmentList: (state) => state.equipment_list,
  jobsList: (state) => state.jobs_list,
  conditionsList: (state) => state.conditions_list,
  isEquipmentMovementFormOpen: (state) => state.equipment_movement_form_open,
  equipmentMovementFormType: (state) => state.equipment_movement_form_type,
  lastUpdatedExcelFile: (state) => state.excel_file,
  businessRules: (state) => state.business_rules,
  physicalCount: (state) => state.physicalCount
  // clientEnv:(state)=> state.clientEnv
};
