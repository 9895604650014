import Api from './Api';
import axios from 'axios';

class ClientsApi extends Api {
  constructor() {
    super('client', 'clients');
  }

  async softDelete(clientId) {
    const errorText = 'Could not delete client';
    let res = null;
    try {
      res = await axios.get(`/clients/soft_delete/${clientId}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        } else {
          res = res.data;
        }
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
}
const clientsApi = new ClientsApi();

export default clientsApi;
