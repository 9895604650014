<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="9"
        class="d-flex justify-end"
        v-if="canEdit"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.ColorForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Colour
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          id="movements-table"
          :headers="colorsHeaders"
          :items="colors"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr>
              <td :class="{ 'red-theme': item.enabled == 0 }">
                <TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': item.enabled == 0 }">
                <TextHighlight :queries="search">
                  {{ item.code }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': item.enabled == 0 }">
                <TextHighlight :queries="search">
                  {{ item.name }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': item.enabled == 0 }">
                <TextHighlight :queries="search">
                  {{ item.created_at }}
                </TextHighlight>
              </td>
              <td :class="{ 'red-theme': item.enabled == 0 }">
                <TextHighlight :queries="search">
                  {{ item.updated_at }}
                </TextHighlight>
              </td>
              <td style="text-align:right">
                <template>
                  <a
                    @click="openProfileForm(item)"
                    class="mr-2"
                  > EDIT </a>
                  <a
                    @click="deleted(item.id)"
                    class="mr-2"
                  >DELETE</a>
                </template>
                <template v-if="item.enabled == 1">
                  <a @click="disable(item.id)">DISABLE</a>
                </template>
                <template v-else>
                  <a @click="enable(item.id)">ENABLE</a>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <ColorForm
      ref="ColorForm"
      @profile-creation-success="notifyProfileCreationSuccess"
      @profile-creation-fail="notifyProfileCreationFail"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Snackbar from '../../components/Snackbar';
import ColorForm from '../../components/forms/ColorForm';
import ColorsApi from '../../api/ColorsApi';

export default {
  name: 'Profiles',
  components: {
    Snackbar,
    TextHighlight,
    ColorForm,
  },
  data: () => ({
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    colorsHeaders: [
      { text: 'Colour ID', value: 'id' },
      { text: 'Colour Code', value: 'code' },
      { text: 'Colour Name', value: 'name' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', align: 'end' },
      
      // { text: 'Updated At', value: 'updated_at' },
      // { text: 'Actions', value: 'actions', align: 'end' },
    ], 
    colors: [],
    canEdit: false,
  }),
  filters: {},
  created() {
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchProfiles()]);
    },
    openProfileForm(item) {
      this.$refs.ColorForm.openDialog(item);
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyProfileCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchProfiles();
    },
    notifyProfileCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchProfiles() {
      this.loading = true;
      this.colors = await ColorsApi.getList();
      
          let arr = this.colors;
          let resultsArr = [];
    

    for( let i = 0; i < arr.length; i++){ 
      if(arr[i].deleted == 1) {
      } else { 
        resultsArr.push(arr[i]) 
      }
    }
    this.colors = resultsArr;
      this.loading = false;
    },
    openProfile(item) {
      this.$router.push(`/profile/${item.code}`);
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async deleted(id) {
      let confirmation = confirm('Are you sure that you want to delete?')
      if(confirmation) {
        let data = new FormData();
        data.append('id', id)
        data.append('deleted', 1)
        const res = await ColorsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
    async enable(id) {
      let confirmation = confirm('Are you sure that you want to enable?')
      if(confirmation) {
        let data = new FormData();
        data.append('id', id);
        data.append('enabled', 1)
        const res = await ColorsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
    async disable(id) {
      let confirmation = confirm('Are you sure that you want to disable?')
      if(confirmation) {
        let data = new FormData();
        data.append('id', id)
        data.append('enabled', 0)
        const res = await ColorsApi.store(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchProfiles();
      }
    },
    
  },
  
};
</script>
<style scoped>
.profile-photo{
  max-width: 100px;
}
.text-right{
  width: 85px!important;
}
.red-theme{
  color: #fa7575!important;;
}</style>
