<template>
  <v-container
    fluid
    class="px-4"
  >
    <v-expansion-panels
      v-model="panel"
      :disabled="disabled"
    >
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Organization</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Organization />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Raw Materials (Items)</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Items />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Caution / Danger levels</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <DangerLevels />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Equipment</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Equipment />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Users</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Users />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Material Colours</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Colors />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Material Types (Inventory Categories)</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <InventoryCategories />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Data Import from Excel</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <ExcelConverter />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Business Rules</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <BusinessRules />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Physical Count List</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <PhysicalCountList />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Items from './Items';
import DangerLevels from './DangerLevels';
import Equipment from './Equipment';
import Users from './Users';
import Organization from './Organization';
import Colors from './Colors.vue';
import InventoryCategories from './InventoryCategories.vue';
import ExcelConverter from './ExcelConverter.vue';
import BusinessRules from './BusinessRules.vue';
import PhysicalCountList from './PhysicalCountList'

export default {
  name: 'Index',
  components: {
    Users,
    DangerLevels,
    Equipment,
    Items,
    Organization,
    Colors,
    InventoryCategories,
    ExcelConverter,
    BusinessRules,
    PhysicalCountList
  },
  data: () => ({
    selectedTab: 0,
    panel: null,
    disabled: null,
  }),
  filters: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // const selectedTab = this.$store.getters.selectedAdmiii;
      // if (selectedTab) {
      //   this.selectedTab = selectedTab;
      // }
    },
    changeTab() {
      // Keep current tab in local storage
      // this.$store.commit('storeInventoryTab', this.selectedTab);
    },
  },
};
</script>
<style scoped>
</style>
