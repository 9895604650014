import Api from './Api';
import axios from 'axios';

class RecordsApi extends Api {
  constructor() {
    super('rmc_consumption_record', 'rmc_consumption_records');
  }

  async getListByConsumption(consumptionId) {
    let list = [];
    const errorText = 'Could not fetch Consumption Items list';

    try {
      list = await axios.get(`/rmc_consumption_records/${consumptionId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }
  async delete(recordId) {
    let list = [];
    const errorText = 'Could not delete';

    try {
      list = await axios.post(`/rmc_consumption_records/${recordId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

}
const recordsApi = new RecordsApi();

export default recordsApi;