<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search..."
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="9"
        class="d-flex justify-end"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="openUserForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new user
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="usersHeaders"
          :items="users"
          :footer-props="usersTableFooterProps"
          :items-per-page="usersPerPage"
          :search="search"
          :loading="loading"
          dense
        >
          <template #item="{ item }">
            <tr>
              <td>
                <TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.username ? item.username : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.first_name }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.last_name }} 
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.roles.map(r => r.name).toString() }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.email ? item.email : '-' }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.phone_number ? item.phone_number : '-' }}
                </TextHighlight>
              </td>
              <td>
                {{ item.created_at }}
              </td>
              <td>
                {{ item.updated_at }}
              </td>
              <td class="text-right">
                <a @click="changeUserStatus(item)">
                  <template v-if="item.enabled">DISABLE</template>
                  <template v-else>ENABLE</template>
                </a>
                <a
                  @click="openEditUserForm(item)"
                  class="ml-2"
                > EDIT </a>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <user-form
      ref="userForm"
      :user-p="user"
      :title="formTitle"
      @user-creation="fetchUsers"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import UserForm from '../../components/forms/UserForm';
import UsersApi from '../../api/UsersApi';
import Bus from '../../bus';

export default {
  name: 'Users',
  components: {
    TextHighlight,
    UserForm,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    users: [],
    usersHeaders: [
      { text: 'User Code', value: 'id' },
      { text: 'Username', value: 'username' },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Roles', value: 'role.name' },
      { text: 'Email', value: 'email' },
      { text: 'Phone', value: 'phone_number' },
      {
        text: 'Created At',
        value: 'created_at',
        filterable: false,
      },
      {
        text: 'Updated At',
        value: 'updated_at',
        filterable: false,
      },
      {
        text: 'Actions',
        align: 'right',
        filterable: false,
        sortable: false,
      },
    ],
    usersTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    usersPerPage: 20,
    user: null,
    formTitle: ''
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchUsers();
    },
    openEditUserForm(item){
      console.log(item)
      this.user = JSON.parse(JSON.stringify(item)) 
      this.formTitle = `Edit User #${item.id}`
      this.$refs.userForm.openDialog();
    },
    openUserForm() {
      this.user = null
      this.formTitle = 'New User'
      this.$refs.userForm.openDialog();
    },
    async changeUserStatus(item) {
      const res = await UsersApi.changeStatus(item.id);
      if (res) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchUsers();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    async fetchUsers() {
      this.loading = true;
      this.users = await UsersApi.getList();
      console.log(this.users)
      this.loading = false;
    },
  },
};
</script>
<style scoped></style>
