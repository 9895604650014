import Api from './Api';
import axios from 'axios';
class InventoryTagsApi extends Api {
  constructor() {
    super('inventory_tag', 'inventory_tags');
  }

  async getTagNames() {
    const errorText = 'Could not find qr code';
    let list = null;
    try {
      list = await axios.get('inventory_tags/tag-names');
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }
    return list;
  }
  async getInventoryTag(tag){
    const errorText = 'Could not find Inventory Tag';
    let res = null;
    try {
      res = await axios.get(`inventory_tags/${tag}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        } else {
          res = res.data;
        }
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }

  async addTag(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post('inventory_tags/add-tag', data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }
  
}
const inventoryTagsApi = new InventoryTagsApi();

export default inventoryTagsApi;
