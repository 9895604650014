import Api from './Api';
import axios from 'axios';

class InventoryQrCodesApi extends Api {
  constructor() {
    super('inventory_qr_codes', 'inventory_qr_codes');
  }

  async getByCode(qrCode) {
    const errorText = 'Could not find qr code';
    let res = null;
    try {
      res = await axios.get(`/inventory_qr_codes/${qrCode}`);
      if (res.status === 200) {
        if (res.data.err) {
          return {
            err: true,
            errDesc: res.data.err_desc,
          };
        } else {
          res = res.data;
        }
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = null;
    }
    return res;
  }
  async store(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}`, data);
      if (res.status === 200) {
        res = res.data;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

}

const inventoryQrCodes = new InventoryQrCodesApi();

export default inventoryQrCodes;
