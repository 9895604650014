<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        cols="9"
        class="d-flex justify-end"
      >
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.equipmentItemForm.openDialog();
            }
          "
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          new equipment
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="itemsHeaders"
          :items="items"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search" 
          :loading="loading"
          dense
        >
          <template #[`item.code`]="{ item }">
            <text-highlight :queries="search">
              {{ item.code }}
            </text-highlight>
          </template>
          <template #[`item.category`]="{ item }">
            <text-highlight :queries="search">
              {{ item.category }}
            </text-highlight>
          </template>
          <template #[`item.manufacturer`]="{ item }">
            <text-highlight :queries="search">
              {{ item.manufacturer }}
            </text-highlight>
          </template>
          <template #[`item.model`]="{ item }">
            <text-highlight :queries="search">
              {{ item.model }}
            </text-highlight>
          </template>
          <template #[`item.serial_number`]="{ item }">
            <text-highlight :queries="search">
              {{ item.serial_number ? item.serial_number : '-' }}
            </text-highlight>
          </template>
          <template #[`item.item_reference`]="{ item }">
            <text-highlight :queries="search">
              {{ item.item_reference ? item.item_reference : '-' }}
            </text-highlight>
          </template>
          <template #[`item.updated_by`]="{ item }">
            <text-highlight :queries="search">
              {{ item.updated_by }}
            </text-highlight>
          </template>
          <template #[`item.actions`]="{ item }">
            <a
              class="mr-2"
              @click="openItemForm(item)"
            > EDIT </a>
            <a
              class="mr-2"
              @click="openItemForm(item, true)"
            > CLONE </a>
            <a @click="deleteItem(item)"> DELETE </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />
    <EquipmentItemForm
      ref="equipmentItemForm"
      @item-creation-success="notifyItemCreationSuccess"
      @item-creation-fail="notifyItemCreationFail"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Snackbar from '../../components/Snackbar';
import Bus from '../../bus';
import EquipmentItemForm from '../../components/forms/EquipmentItemForm';
import EquipmentItemsApi from '../../api/EquipmentItemsApi';

export default {
  name: 'Equipment',
  components: {
    'text-highlight': TextHighlight,
    Snackbar,
    EquipmentItemForm,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsHeaders: [
      { text: 'Equipment Id', value: 'code', width: '7%' },
      { text: 'Category', value: 'category', width: '13%' },
      { text: 'Manufacturer', value: 'manufacturer', width: '10%' },
      { text: 'Model', value: 'model', width: '15%' },
      { text: 'Serial Number', value: 'serial_number', width: '10%' },
      { text: 'Item Reference', value: 'item_reference', width: '10%' },
      { text: 'Updated By', value: 'updated_by', width: '10%' },
      { text: 'Updated At', value: 'updated_at', width: '10%' },
      { text: 'Actions', value: 'actions', align: 'end', width: '15%' },
    ],
    items: [],
  }),
  filters: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchEquipmentItems();
    },
    openItemForm(item, isClone = false) {
      if (isClone) {
        this.$refs.equipmentItemForm.openDialog(item, isClone);
      } else {
        this.$refs.equipmentItemForm.openDialog(item);
      }
    },
    async deleteItem(item) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await EquipmentItemsApi.softDelete(item.id);
      if (res.err) {
        if (res.errDesc === 'equipment_not_empty') {
          Bus.$emit(
            'alert',
            'error',
            'Error! Equipment for this item is not empty.'
          );
        }
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchEquipmentItems();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    notifyItemCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchEquipmentItems();
    },
    notifyItemCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchEquipmentItems() {
      this.loading = true;
      this.items = await EquipmentItemsApi.getList();
      this.loading = false;
    },
  },
};
</script>
<style scoped></style>
