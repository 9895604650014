<template>
  <v-container>
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          outlined
          
          @click="openPhysicalCountForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Physical Count
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          ref="pcTable"
          :headers="headers"
          :items="physicalCountLists"
          :footer-props="itemsTableFooterProps"
          :items-per-page="itemsPerPageProps"
          :loading="loading"
          dense
        >
          <template #[`item.id`]="{ item }">
            {{ item.id + 1 }}
          </template>
          <template #[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at }}
          </template>
          <template #[`item.created_by`]="{ item }">
            {{ `${item.user.first_name} ${item.user.last_name}` }}
          </template>
          <template #[`item.actions`]="{ item }">
            <v-chip
              color="#bf0000"
              text-color="white"
              small
              v-if="item.is_active"
              class="mr-2"
            >
              ACTIVATED
            </v-chip>
            <a
              v-else
              @click="activate(item)"
              class="mr-5"
            > ACTIVATE </a>
            <a
          
              @click="editForm(item)"
              class="mr-2"
            > EDIT </a>
            <a
          
              @click="softDelete(item)"
              class="mr-2"
            > DELETE </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    
    
    <physical-count-list-form 
      ref="listForm"
      :physical-count-prop="physicalCount"
      @list-form-success="listFormSuccess"
    />
    <Snackbar ref="snackbar" />
  </v-container>
</template>


<script>
import PhysicalCountListApi from '@/api/PhysicalCountListApi';
import PhysicalCountListForm from '@/components/forms/PhysicalCountListForm'
export default {
    components: {
        PhysicalCountListForm
    },
    watch: {
        physicalCountLists: function(){
            window.scrollTo(1000, 1000)
        }
    },
    data: () => ({
        loading: false,
        itemsTableFooterProps: {
        'items-per-page-options': [10, 20, 50, -1],
        },
        headers: [
            { text: 'Id', value: 'id', },
            { text: 'Physical Count Name', value: 'name' },
            { text: 'Date',value: 'date' },
            { text: 'Created By',value: 'created_by' },
            { text: 'Actions',value: 'actions', align: 'end' },
            
        ],
        itemsPerPageProps: 20,
        physicalCountLists: [],
        physicalCount: null

        
    }),
     created() {
        this.initialize();
    },
    methods: {
        initialize() {
          this.getPhysicalCountList()
          
  
        },

        async getPhysicalCountList(){
            this.loading = true;
            this.physicalCountLists = await PhysicalCountListApi.getList();
            this.loading = false;
            
        },

        async activate(item){
            if(!item) return
            let confirmation = confirm(`Are you sure that you want to activate this Physical Count "${item.name}"? This will deactivate the current Physical Count "${this.physicalCount.name}".`)
            if(!confirmation) return
            var response = await PhysicalCountListApi.activate(item.id)
            if(!response){
                this.$refs.snackbar.showError()
                return
            }

            this.$refs.snackbar.showSuccess(`Physical Count "${item.name}" has been Activated`)
            this.$store.commit('storeActivatedPhysicalCount', item);
            this.getPhysicalCountList()
        },

        openPhysicalCountForm(){
            this.$refs.listForm.openPhysicalCountForm()
             
        },

        listFormSuccess(message){
            this.$refs.snackbar.showSuccess(message)
            this.getPhysicalCountList()
        },

        editForm(item){
            this.physicalCount = JSON.parse(JSON.stringify(item))
            this.$refs.listForm.openPhysicalCountForm()
        },

        async softDelete(item){
            let confirmation = confirm('Are you sure that you want to delete?')
            if(!confirmation) return
            if(item.is_active){
                this.$refs.snackbar.showError('Active Physical Count cannot be deleted')
                return
            }
            var response = await PhysicalCountListApi.delete(item.id)
            if(!response) 
            {
                this.$refs.snackbar.showError()
                return
            }

            this.getPhysicalCountList()
            this.$refs.snackbar.showSuccess('Physical Count has been deleted')

        }

        
    }

}


</script>

