<template>
  <v-navigation-drawer
    v-model="drawer"
    :color="color"
    :mini-variant="miniVariant"
    :drawer="drawer"
    permanent
    dark
    fixed
    :app="true"
    stateless
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-list-item two-line>
        <div :class="{ 'pr-3': !miniVariant }">
          <!-- <img
            v-if="miniVariant"
            class="d-flex align-center justify-center logo-size"
            src="@/assets/logo-mini.png"
          />
          <img
            v-else
            class="d-flex align-center logo-size"
            src="@/assets/logo-mini.png"
          /> -->
        </div>
        <v-list-item-content style="margin-top: 2px">
          <v-list-item-title style="font-size: 1.3rem">
            ERP
          </v-list-item-title>
          <v-list-item-subtitle>v{{ appVersion }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- Bottom Items -->
    <template>
      <v-list
        dense
        nav
      >
        <v-list-item
          id="clients"
          v-if="isAdmin || isAssociateRole"
          link
          :to="'/clients'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-address-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> CLIENTS </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          id="jobs"
          v-if="isAdmin || isAssociateRole"
          link
          :to="'/jobs'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-suitcase</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> JOBS </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          id="consumptions"
          v-if="isAdmin || isAssociateRole || isMaterialhandler"
          link
          :to="'/consumptions'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-truck-loading</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <!-- Was RM CONSUMPTION before -->
            <v-list-item-title> RM SUPPLY / RETURN </v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
          id="inventory"
          v-if="isAdmin || isAssociateRole || isMaterialhandler"
          link
          :to="'/inventory'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-boxes</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> INVENTORY </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          id="inventory-qr"
          v-if="isAdmin || isAssociateRole || isMaterialhandler"
          link
          :to="'/inventory-qr'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-qrcode</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> INVENTORY QR </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
        <v-list-item
          id="physical-count-qr"
          v-if="isAdmin || isAssociateRole || isPhysicalCountHandler"
          link
          :to="'/physical-count-qr'"
        >
          <v-list-item-icon>
            <v-icon color="pink">
              fas fa-qrcode
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> PHYSICAL COUNT </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.link"
          :id="item.id"
        >
          <!-- Menu item with nested subitems if there are any -->
          <v-list-group
            v-if="item.subItems"
            class="navbar-grouped-item"
            color="white"
            value="true"
            v-model="isEquipmentSubmenuOpen"
          >
            <template #activator>
              <v-list-item-icon>
                <v-icon class="navbar-item-icon">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="subItem in item.subItems"
              :key="subItem.title"
              @click="() => openEquipmentMovementForm(subItem.title)"
              class="navbar-subitem"
            >
              <v-list-item-icon style="margin-right: 15px">
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <div style="font-size: 0.75rem">
                {{ subItem.title }}
              </div>
            </v-list-item>
          </v-list-group>
        </v-list-item>
      </v-list>
    </template>

    <!-- Bottom Items -->
    <template #append>
      <v-list
        dense
        nav
        class="pb-6"
      >
        <v-list-item v-if="!miniVariant">
          <v-list-item-icon>
            <v-icon>fas fa-user</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ currentUserName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin"
          link
          :to="'/admin'"
        >
          <v-list-item-icon>
            <v-icon>fas fa-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> ADMIN </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> LOG OUT </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ModulesApi from '@/api/ModulesApi';
import BusinessRulesApi from '@/api/BusinessRulesApi';
export default {
  name: 'NavigationDrawer',
  components: {},
  data: () => ({
    drawer: true,
    isEquipmentSubmenuOpen: false,
    items: [
      // {
      //   title: 'CLIENTS',
      //   icon: 'fas fa-address-card',
      //   link: '/clients',
      //   id: 'clients',
      // },
      // { title: 'JOBS', icon: 'fas fa-suitcase', link: '/jobs', id: 'jobs' },
      // {
      //   title: 'RM CONSUMPTION',
      //   icon: 'fas fa-truck-loading',
      //   link: '/consumptions',
      //   id: 'consumptions',
      // },
      // {
      //   title: 'INVENTORY',
      //   icon: 'fas fa-boxes',
      //   link: '/inventory',
      //   id: 'inventory',
      // },
      {
        title: 'EQUIPMENT',
        icon: 'fas fa-hammer',
        link: '/equipment',
        id: 'equipment',
        subItems: [
          { title: 'CHECK - IN', icon: 'fas fa-arrow-right' },
          { title: 'CHECK - OUT', icon: 'fas fa-arrow-left' },
          { title: 'CHANGE SITE', icon: 'fas fa-random' },
          { title: 'REQUEST REPAIR', icon: 'fas fa-tools' },
          { title: 'REPAIR DONE', icon: 'fas fa-check' },
        ],
      },
      // { title: 'REPORTS', icon: 'fas fa-chart-bar', link: 'reports' },
    ],
    color: '#323232',
    miniVariant: false,
    currentUserName: '',
    appVersion: '3.0.1',
    name: '',
    enabled: null,
    enabled_jobs: null,
    enabled_consumption: null,
    enabled_inventory: null,
    enabled_equipment: null,
    enabled_inventory_qr: null,
    enabled_physical_count_qr: null,
    isAdmin: false,
    isAssociateRole: false,
    isMaterialhandler: false,
    isEquipmentHandler: false,
    isPhysicalCountHandler: false,

  }),
  created() {
    this.initialize();
    document.title = process.env.VUE_APP_TITLE
      ? process.env.VUE_APP_TITLE
      : 'Zita ERP';
  },
  // computed: {
  //   appVersion() {
  //     return this.$store.getters.appVersion;
  //   },
  // },
  methods: {
    initialize() {
      Promise.all([this.fetchModules(), this.fetchBusinessRules()]);
      const currentUser = this.$store.getters.user;
      this.currentUserName = `${currentUser.first_name} ${currentUser.last_name}`;
      this.isAdmin = currentUser.roles.some(e => e.name === 'Admin') ? true : false
      this.isAssociateRole = currentUser.roles.some(e => e.name === 'Associate') ? true : false
      this.isMaterialhandler = currentUser.roles.some(e => e.name === 'Material Handler') ? true : false
      this.isEquipmentHandler = currentUser.roles.some(e => e.name === 'Equipment Handler') ? true : false
      this.isPhysicalCountHandler = currentUser.roles.some(e => e.name === 'Phyical Count Handler') ? true : false

    },
    openEquipmentMovementForm(type) {
      this.$store.commit('openEquipmentMovementForm', type);
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
    },
    // changeClients() {
    //   if (this.clients === true) {
    //     document.getElementById('clients').style.display = 'flex';
    //   } else {
    //     document.getElementById('clients').style.display = 'none';
    //   }
    // },
    // changeJobs() {
    //   if (this.jobs === true) {
    //     document.getElementById('jobs').style.display = 'flex';
    //   } else {
    //     document.getElementById('jobs').style.display = 'none';
    //   }
    // },
    // changeConsumption() {
    //   if (this.consumption === true) {
    //     document.getElementById('consumptions').style.display = 'flex';
    //   } else {
    //     document.getElementById('consumptions').style.display = 'none';
    //   }
    // },
    // changeInventory() {
    //   if (this.inventory === true) {
    //     document.getElementById('inventory').style.display = 'flex';
    //   } else {
    //     document.getElementById('inventory').style.display = 'none';
    //   }
    // },
    // // changeEquipment() {
    // //   if (this.equipment === true) {
    // //     document.getElementById('equipment').style.display = 'flex';
    // //   } else {
    // //     document.getElementById('equipment').style.display = 'none';
    // //   }
    // // },
    async fetchModules() {
      this.modules = await ModulesApi.getList();
      console.log(this.modules)
      this.name = this.modules[0].name;
      this.enabled = this.modules[0].enabled;
      this.enabled_jobs = this.modules[1].enabled;
      this.enabled_consumption = this.modules[2].enabled;
      this.enabled_inventory = this.modules[3].enabled;
      this.enabled_equipment = this.modules[4].enabled;
      this.enabled_inventory_qr = this.modules[7].enabled;
      this.enabled_physical_count_qr = this.modules[8].enabled;
      let clients = document.getElementById('clients');
      if (this.enabled === 0) {
        if (document.getElementById('clients'))
          document.getElementById('clients').style.display = 'none';
      } else {
        if (document.getElementById('clients'))
          document.getElementById('clients').style.display = 'flex';
      }
      if (this.enabled_jobs === 0) {
        if (document.getElementById('jobs'))
          document.getElementById('jobs').style.display = 'none';
      } else {
        if (document.getElementById('jobs'))
          document.getElementById('jobs').style.display = 'flex';
      }
      if (this.enabled_consumption === 0) {
        if (document.getElementById('consumptions'))
          document.getElementById('consumptions').style.display = 'none';
      } else {
        if (document.getElementById('consumptions'))
          document.getElementById('consumptions').style.display = 'flex';
      }
      if (this.enabled_inventory === 0) {
        if (document.getElementById('inventory'))
          document.getElementById('inventory').style.display = 'none';

      } else {
        if (document.getElementById('inventory'))
          document.getElementById('inventory').style.display = 'flex';
      }
      if (this.enabled_inventory_qr === 0) {
        if (document.getElementById('inventory-qr'))
          document.getElementById('inventory-qr').style.display = 'none';
      } else {
        if (document.getElementById('inventory-qr'))
          document.getElementById('inventory-qr').style.display = 'flex';
      }
      if (this.enabled_physical_count_qr === 0) {
        if (document.getElementById('physical-count-qr'))
          document.getElementById('physical-count-qr').style.display = 'none';
      } else {
        if (document.getElementById('physical-count-qr'))
          document.getElementById('physical-count-qr').style.display = 'flex';
      }
      if (this.enabled_equipment === 0) {
        if (document.getElementById('equipment'))
          document.getElementById('equipment').style.display = 'none';
      } else {
        if (document.getElementById('equipment'))
          document.getElementById('equipment').style.display = 'flex';
      }
    },
    async fetchBusinessRules() {
      const businessRules = await BusinessRulesApi.getList();
      this.$store.commit('storeBusinessRules', businessRules);
    },
  },
};
</script>

<style scoped>
.navbar-grouped-item {
  width: 100%;
}

.navbar-grouped-item >>> div {
  padding: 0;
}

.navbar-item-icon {
  width: 1.5rem;
}

.navbar-subitem {
  margin-left: 2rem;
}

.logo-size {
  max-width: 100px;
}
</style>
