<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="2">
        <v-checkbox v-model="enabled_material_id" @click="changeMaterialID(enabled_material_id)">
          <template v-slot:label>
            <div>Material ID</div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="enabled_unique_item_code" @click="changeUniqueItemCode(enabled_unique_item_code)">
          <template v-slot:label>
            <div>Unique Item Code</div>
          </template>
        </v-checkbox>
      </v-col> -->
      <v-col cols="9" class="d-flex justify-end">
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
           style="margin-top: 0"
          @click="
            () => {
              this.$refs.itemForm.openDialog();
            }
          "
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
          new item
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="itemsHeaders"
          :items="items"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template v-if="enabled_item_image=='1'" v-slot:[`item.photo`]="{ item }">
            <a @click="openItemShowImageForm(item.photo, item.mime_type, item.material_id)" v-if="item.photo" > SHOW </a>
          </template>
          <template v-slot:[`item.unique_code`]="{ item }">
            <text-highlight :queries="search">
              {{ item.unique_code }}
            </text-highlight>
          </template>
          <template v-if="enabled_material_id==1" v-slot:[`item.material_id`]="{ item }">
            <text-highlight :queries="search">
              {{ item.material_id }}
            </text-highlight>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <text-highlight :queries="search">
              {{ item.name }}
            </text-highlight>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <text-highlight :queries="search">
              {{ item.category }}
            </text-highlight>
          </template>
          <template v-slot:[`item.manufacturers`]="{ item }">
            <text-highlight :queries="search">
              {{ item.manufacturers ? item.manufacturers : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.uoms`]="{ item }">
            <text-highlight :queries="search">
              {{ item.uoms }}
            </text-highlight>
          </template>
          <template v-slot:[`item.material_properties`]="{ item }">
            <text-highlight :queries="search">
              {{ item.material_properties ? item.material_properties : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.lengths`]="{ item }">
            <text-highlight :queries="search">
              {{ item.lengths ? item.lengths : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.colors`]="{ item }">
            <text-highlight :queries="search">
              {{ item.colors_code ? item.colors_code : '-' }} : {{item.colors ? item.colors : '-'}}
            </text-highlight>
          </template>
          <template v-slot:[`item.finishings`]="{ item }">
            <text-highlight :queries="search">
              {{ item.finishings ? item.finishings : '-' }}
            </text-highlight>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <text-highlight :queries="search">
              {{ item.updated_at}}
            </text-highlight>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <a class="mr-2" @click="deleteItem(item)"> DELETE </a>
            <a @click="openItemForm(item)"> EDIT </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar"></Snackbar>
    <InventoryItemForm
      ref="itemForm"
      v-on:item-creation-success="notifyItemCreationSuccess"
      v-on:item-creation-fail="notifyItemCreationFail"
    ></InventoryItemForm>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    ></InventoryItemShowImageForm>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Snackbar from '../../components/Snackbar';
import Bus from '../../bus';
import InventoryItemForm from '../../components/forms/InventoryItemForm';
import InventoryItemShowImageForm from '../../components/forms/InventoryItemShowImageForm';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import ModulesApi from '../../api/ModulesApi';
import BusinessRulesApi from '../../api/BusinessRulesApi';

export default {
  name: 'Items',
  components: {
    'text-highlight': TextHighlight,
    Snackbar,
    InventoryItemForm,
    InventoryItemShowImageForm,
    
  },
  computed:{
    itemsHeaders(){
      let headers = []

      if(this.enabled_item_image == '1')
        headers.push({ text: 'Image', value: 'photo'})

      if(this.enabled_unique_item_code == 1){
        headers.push({text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' })
      }
      if(this.enabled_material_id == 1){
        headers.push({ text: 'Material ID', value: 'material_id', class: 'material_id' })
      }

      headers.push({ text: 'Name', value: 'name', width: '10%' })
      headers.push({ text: 'Category', value: 'category' })
      headers.push({ text: 'Manufacturers', value: 'manufacturers' })
      headers.push({ text: 'Units Of Measurement', value: 'uoms', width: '11%' })
      headers.push({ text: 'Material Properties', value: 'material_properties',width: '10%'})
      headers.push({ text: 'Lengths', value: 'lengths' })
      headers.push({ text: 'Colors', value: 'colors' })
      headers.push({ text: 'Finishings', value: 'finishings', width: '7%' })
      headers.push({ text: 'Updated At', value: 'updated_at', width: '7%' })
      headers.push({ text: 'Actions', value: 'actions', align: 'end', width: '9%' })

      return headers;

    }
  },
  watch: {
    businessRules: function (val){
    }
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    enabled_material_id: null,
    enabled_unique_item_code: null,
    enabled_item_image: null,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    items: [],
  }),
  filters: {},
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Bus.$on('updateBusinessRules', () => {
        this.fetchBusinessRules();
      });
      await this.fetchInventoryItems();
      //await this.fetchModules();
      await this.fetchBusinessRules();
    },
    openItemForm(item) {
      this.$refs.itemForm.openDialog(item);
    },
    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },
    async deleteItem(item) {
      if (!confirm('Are you sure?')) {
        return;
      }

      const res = await InventoryItemsApi.softDelete(item.id);
      if (res.err) {
        if (res.errDesc === 'inventory_not_empty') {
          Bus.$emit(
            'alert',
            'error',
            'Error! Inventory for this item is not empty.'
          );
        }
      } else if (res === true) {
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchInventoryItems();
      } else {
        Bus.$emit('alert', 'error', '');
      }
    },
    notifyItemCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchInventoryItems();
    },
    notifyItemCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    openInventoryImageShowForm() {
      this.$refs.inventoryImageShowForm.openDialog();
    },
    async fetchInventoryItems() {
      this.loading = true;
      this.items = await InventoryItemsApi.getList();
      this.loading = false;
    },
    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },
    async fetchBusinessRules(){
      const businessRules = this.$store.getters.businessRules;

      var enabled_item_image = businessRules.find(x => x.name === 'Item Image');
      this.enabled_item_image = enabled_item_image != null ? enabled_item_image.value : 0; 
      
      var enabled_material_id = businessRules.find(x => x.name === 'Material Id');
      this.enabled_material_id = enabled_material_id != null ? enabled_material_id.value : 0;

      var enabled_unique_item_code = businessRules.find(x => x.name === 'Unique Item Code');
      this.enabled_unique_item_code = enabled_unique_item_code != null ? enabled_unique_item_code.value : 0; 
      
    }
    // async changeMaterialID(enabled_material_id) {
    //   let confirmation = confirm(
    //     'Are you sure you want to enable it??'
    //   );
    //   if (confirmation) {
    //     this.enabled_material_id = enabled_material_id;
    //     if (enabled_material_id) {
    //       const data = {
    //         id: 7,
    //         enabled: 1,
    //       };
    //       const res = await ModulesApi.store(data);
    //     } else {
    //       const data = {
    //         id: 7,
    //         enabled: 0,
    //       };
    //       const res = await ModulesApi.store(data);
    //     }
    //   }
    // },
    // async changeUniqueItemCode(enabled_unique_item_code) {
    //   let confirmation = confirm(
    //     'Are you sure that you want to change the enabled?'
    //   );
    //   if (confirmation) {
    //     this.enabled_unique_item_code = enabled_unique_item_code;
    //     if (enabled_unique_item_code) {
    //       const data = {
    //         id: 8,
    //         enabled: 1,
    //       };
    //       const res = await ModulesApi.store(data);
    //     } else {
    //       const data = {
    //         id: 8,
    //         enabled: 0,
    //       };
    //       const res = await ModulesApi.store(data);
    //     }
    //   }
    // },
    // async fetchModules() {
    //   this.modules = await ModulesApi.getList();
    //   this.enabled_material_id = this.modules[5].enabled;
    //   this.enabled_unique_item_code = this.modules[6].enabled;
    //   if(this.enabled_material_id == 0) {
    //     if(document.getElementsByClassName('material_id')) document.getElementsByClassName('material_id')[0].style.display = 'none';
    //   } 
    //   if(this.enabled_unique_item_code == 0) {
    //     if(document.getElementsByClassName('unique_item_code')) document.getElementsByClassName('unique_item_code')[0].style.display = 'none';
    //   } 
    // },
    
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 100px;
}
</style>
