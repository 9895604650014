<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px" :persistent="true">
      <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert v-model="errorAlert" type="error">
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    v-model="unit"
                    label="Unit"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="pb-0">
                  <v-text-field
                    v-model="building"
                    label="Building*"
                    :rules="[(v) => !!v || 'Building is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="street"
                    label="Street*"
                    :rules="[(v) => !!v || 'Street is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    v-model="city"
                    label="City*"
                    :rules="[(v) => !!v || 'City is required']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-autocomplete
                    v-model="region"
                    label="Province/State*"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Province/State is required']"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-text-field
                    v-model="postal_code"
                    label="Postal Code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-autocomplete
                    v-model="country"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="Country*"
                    :rules="[(v) => !!v || 'Country is required']"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="name" label="Name"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    v-model="phoneNumber"
                    label="Phone Number"
                    v-on:blur="formatPhoneNumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="normal" class="mr-2" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submitForm">
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  components: {},
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    countries: [],
    regions: [],
    id: null,
    name: '',
    unit: '',
    building: '',
    street: '',
    city: '',
    region: '',
    postal_code: '',
    country: '',
    phoneNumber: '',
  }),
  watch: {
    // Auto select country based on region
    region: function (val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id
        ).id;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      Promise.all([this.fetchCountries(), this.fetchRegions()]);
    },
    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        await this.$refs.form.reset();
        if (item) {
          this.dialogTitle = 'Edit Client';
          this.btnSubmitName = 'Update';
          this.id = item.id;
          this.name = item.name || '';
          this.unit = item.unit || '';
          this.building = item.building;
          this.street = item.street;
          this.city = item.city;
          this.region = item.region.id;
          this.postal_code = item.postal_code || '';
          this.country = item.country.id;
          this.phoneNumber = item.phone_number || '';
        } else {
          this.dialogTitle = 'New Client';
          this.btnSubmitName = 'Create';
          this.id = null;
          this.name = '';
          this.unit = '';
          this.building = '';
          this.street = '';
          this.city = '';
          this.region = '';
          this.postal_code = '';
          this.country = '';
          this.phoneNumber = '';
          this.country = this.countries.find((x) => x.name === 'Canada').id;
          this.region = this.regions.find((x) => x.name === 'Ontario').id;
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const data = {
          id: this.id,
          name: this.name.trim(),
          unit: this.unit.trim(),
          building: this.building.trim(),
          street: this.street.trim(),
          city: this.city.trim(),
          postal_code: this.postal_code.trim(),
          country_id: this.country,
          region_id: this.region,
          phone_number: this.phoneNumber.trim(),
        };
        try {
          const res = await axios.post('clients/create', data);
          if (res.status == 200) {
            this.$emit('client-creation-success');
          } else {
            this.$emit('client-creation-fail');
          }
          this.dialog = false;
        } catch (err) {
          this.$emit('client-creation-fail');
        }
      }
    },
    showAlert(message) {
      this.errorAlert = true;
      this.errorAlertText = message;
      setTimeout(() => {
        this.errorAlert = false;
      }, 3000);
    },
    formatPhoneNumber() {
      this.phoneNumber = FormatHelpers.formatPhoneNumber(this.phoneNumber);
    },
    async fetchCountries() {
      let countries = await axios.get('/countries');
      if (countries.status === 200) {
        this.countries = countries.data;
      } else {
        console.error('Could not fetch countries');
      }
    },
    async fetchRegions() {
      let regions = await axios.get('/regions');
      if (regions.status === 200) {
        this.regions = regions.data;
      } else {
        console.error('Could not fetch regions');
      }
    },
  },
};
</script>
