import Api from './Api';
import axios from 'axios';

class ConsumptionsApi extends Api {
  constructor() {
    super('rmc_consumption', 'rmc_consumptions');
  }

  async getSignleById(consumptionId) {
    let list = [];
    const errorText = 'Could not fetch Consumption Items By Id';

    try {
      list = await axios.get(`/rmc_consumptions/${consumptionId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

  async getConsumptionsByRole(userId) {
    let list = [];
    const errorText = 'Could not fetch Consumption Items By Id';

    try {
      list = await axios.get(`/rmc_consumptions/consumptions_by_role/${userId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }
  async delete(recordId) {
    let list = [];
    const errorText = 'Could not delete';

    try {
      list = await axios.post(`/rmc_consumptions/delete/${recordId}`);
      if (list.status === 200) {
        list = list.data;
      } else {
        console.error(errorText);
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      list = [];
    }

    return list;
  }

}
const consumptionsApi = new ConsumptionsApi();

export default consumptionsApi;
