var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"9"}},[(_vm.canEdit)?_c('v-btn',{staticClass:"d-flex align-self-end",attrs:{"color":"primary","outlined":""},on:{"click":function () {
            this$1.$refs.clientForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("fas fa-plus")]),_vm._v(" new client ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clients,"items-per-page":15,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name ? item.name : '-')+" ")])]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.city",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.city)+" ")])]}},{key:"item.region.name",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.region.name)+" ")])]}},{key:"item.postal_code",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.postal_code ? item.postal_code : '-')+" ")])]}},{key:"item.country.name",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.country.name)+" ")])]}},{key:"item.phone_number",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.phone_number ? item.phone_number : '-')+" ")])]}},(_vm.canEdit)?{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteClient(item)}}},[_vm._v(" DELETE ")]),_c('a',{on:{"click":function($event){return _vm.openClientForm(item)}}},[_vm._v(" EDIT ")])]}}:null],null,true)})],1)],1),_c('Snackbar',{ref:"snackbar"}),_c('ClientForm',{ref:"clientForm",on:{"client-creation-success":_vm.notifyClientCreationSuccess,"client-creation-fail":_vm.notifyClientCreationFail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }