<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search..."
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="levels"
          :footer-props="footerProps"
          :items-per-page="recordsPerPage"
          :search="search"
          :loading="loading"
          dense
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <template v-if="edit === item.id">
                  {{ editItemCode }}
                </template>
                <template v-else>
                  <TextHighlight :queries="search">
                    {{ item.item_code ? item.item_code : '' }}
                  </TextHighlight>
                </template>
              </td>
              <td>
                <template v-if="item.id === 'new'">
                  {{ '' }}
                </template>
                <template v-else>
                  <TextHighlight :queries="search">
                    {{ item.id }}
                  </TextHighlight>
                </template>
              </td>
              <td>
                {{ item.material_id ? item.material_id : '' }}
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id"
                  class="mrp-edit-input-margin pa-0"
                  :items="items"
                  v-model="editItemId"
                  @change="changeEditItem"
                  :item-text="getItemText"
                  item-value="id"
                  required
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{ item.item_name }}</template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedManufacturers.length > 0"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedManufacturers"
                  v-model="editManufacturerId"
                  item-text="name"
                  item-value="id"
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{
                  item.manufacturer_name ? item.manufacturer_name : '-'
                }}</template>
              </td>
              <td>
                <v-autocomplete
                  v-if="
                    edit === item.id && relatedMaterialProperties.length > 0
                  "
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedMaterialProperties"
                  v-model="editMaterialPropertyId"
                  item-text="name"
                  item-value="id"
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{
                  item.material_property_name
                    ? item.material_property_name
                    : '-'
                }}</template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedLengths.length > 0"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedLengths"
                  v-model="editLengthId"
                  item-text="name"
                  item-value="id"
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{
                  item.length_name ? item.length_name : '-'
                }}</template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedColors.length > 0"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedColors"
                  v-model="editColorId"
                  item-text="name"
                  item-value="id"
                  single-line
                >
                </v-autocomplete>
                <template v-else>
                  {{item.color_code ? item.color_code + ':' : ''}}
                  {{item.color_name ? item.color_name : ''}}
                </template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id && relatedFinishings.length > 0"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedFinishings"
                  v-model="editFinishingId"
                  item-text="name"
                  item-value="id"
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{
                  item.finishing_name ? item.finishing_name : '-'
                }}</template>
              </td>
              <td>
                <v-autocomplete
                  v-if="edit === item.id"
                  class="mrp-edit-input-margin pa-0"
                  :items="relatedUoms"
                  v-model="editUomId"
                  item-text="name"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'Required']"
                  single-line
                >
                </v-autocomplete>
                <template v-else>{{
                  item.uom_name ? item.uom_name : '-'
                }}</template>
              </td>
              <td>
                <v-text-field
                  v-if="edit === item.id"
                  class="mrp-edit-input-margin pa-0"
                  type="number"
                  v-model="editCautionLevel"
                  required
                  :rules="[(v) => v > 0 || 'Required']"
                  single-line
                >
                </v-text-field>
                <template v-else>{{ item.caution_level }}</template>
              </td>
              <td>
                <v-text-field
                  v-if="edit === item.id"
                  class="mrp-edit-input-margin pa-0"
                  type="number"
                  v-model="editDangerLevel"
                  required
                  :rules="[(v) => v >= 0 || 'Required']"
                  single-line
                >
                </v-text-field>
                <template v-else>{{ item.danger_level }}</template>
              </td>
              <td class="text-right">
                <template v-if="edit === item.id">
                  <a class="mr-2" @click="edit = null"> CANCEL </a>
                  <a @click="saveLevel"> SAVE </a>
                </template>
                <a v-else-if="item.id === 'new'" @click="editLevel(item)">
                  ADD NEW
                </a>
                <a v-else @click="editLevel(item)"> EDIT </a>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Bus from '../../bus';
import InventoryItemsApi from '../../api/InventoryItemsApi';
import WarehousesApi from '../../api/WarehousesApi';
import DangerLevelsApi from '../../api/DangerLevelsApi';

export default {
  components: {
    TextHighlight,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    levels: [],
    headers: [
      { text: 'Item Code', value: 'item_code', width: '7%' },
      { text: 'SKU Code', value: 'id' },
      { text: 'Material Id', value: 'material_id' },
      { text: 'Item Name', value: 'item_name', width: '15%' },
      { text: 'Manufacturer', value: 'manufacturer_name' },
      { text: 'Material Property', value: 'material_property_name' },
      { text: 'Length', value: 'length_name' },
      { text: 'Color', value: 'color_name' },
      { text: 'Finishing', value: 'finishing_name' },
      { text: 'UOM', value: 'uom_name' },
      { text: 'Caution Level', value: 'caution_level' },
      { text: 'Danger Level', value: 'danger_level' },
      { text: 'Actions', align: 'right', width: '10%' },
    ],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    recordsPerPage: 20,
    edit: null,
    items: [],
    relatedManufacturers: [],
    relatedMaterialProperties: [],
    relatedLengths: [],
    relatedColors: [],
    relatedFinishings: [],
    relatedUoms: [],
    editId: null,
    editItemCode: null,
    editNameId: null,
    editManufacturerId: null,
    editMaterialPropertyId: null,
    editLengthId: null,
    editColorId: null,
    editFinishingId: null,
    editUomId: null,
    editDangerLevel: null,
    editCautionLevel: null,
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await Promise.all([this.fetchItems(), this.fetchLevels()]);
    },
    async changeEditItem() {
      this.fetchItemRelatedProperties(this.editItemId);
    },
    async editLevel(item) {
      this.edit = item.id;

      // New level creation
      // Select first item in list by default
      if (item.id === 'new') {
        this.editItemId = this.items[0].id;
        this.editItemCode = this.items[0].code;
        this.editId = null;
        await this.fetchItemRelatedProperties(this.editItemId);
      }
      // Edit item
      else {
        this.editItemId = item.item_id;
        this.editItemCode = item.item_code;
        this.editId = item.id;

        await this.fetchItemRelatedProperties(this.editItemId);
        this.editManufacturerId = item.manufacturer_id;
        this.editMaterialPropertyId = item.material_property_id;
        this.editLengthId = item.length_id;
        this.editColorId = item.color_id;
        this.editFinishingId = item.finishing_id;
        this.editUomId = item.uom_id;
        this.editDangerLevel = item.danger_level;
        this.editCautionLevel = item.caution_level;
      }
    },
    async saveLevel() {
      if (Number(this.editDangerLevel) < 0) {
        Bus.$emit('alert', 'error', 'Danger Level is required!');
        return;
      }
      if (Number(this.editCautionLevel) <= 0) {
        Bus.$emit('alert', 'error', 'Caution Level is required!');
        return;
      }
      if (Number(this.editCautionLevel) <= this.editDangerLevel) {
        Bus.$emit(
          'alert',
          'error',
          'Caution level has to be bigger than danger level!'
        );
        return;
      }
      if (!this.editUomId) {
        Bus.$emit('alert', 'error', 'UOM is required!');
        return;
      }

      const data = {
        id: this.editId || null,
        inventory_item_id: this.editItemId,
        manufacturer_id: this.editManufacturerId,
        material_property_id: this.editMaterialPropertyId,
        length_id: this.editLengthId,
        color_id: this.editColorId,
        finishing_id: this.editFinishingId,
        uom_id: this.editUomId,
        danger_level: this.editDangerLevel,
        caution_level: this.editCautionLevel,
      };

      const res = await DangerLevelsApi.store(data);
      if (res) {
        this.edit = false;
        Bus.$emit('alert', 'success', 'Success!');
        this.fetchLevels();
      } else {
        this.edit = false;
        Bus.$emit('alert', 'error', '');
      }
    },
    async fetchItemRelatedProperties(itemId) {
      const entries = await InventoryItemsApi.getRelatedProperties(itemId);
      this.editItemCode = entries.item_code;
      this.relatedManufacturers = entries.manufacturers;
      this.relatedMaterialProperties = entries.material_properties;
      this.relatedLengths = entries.lengths;
      this.relatedColors = entries.colors;
      this.relatedFinishings = entries.finishings;
      this.relatedUoms = entries.uoms;

      // Reset selected values
      this.editManufacturerId = null;
      this.editMaterialPropertyId = null;
      this.editLengthId = null;
      this.editColorId = null;
      this.editFinishingId = null;
      this.editUomId = null;
      this.editDangerLevel = null;
      this.editCautionLevel = null;
    },
    async fetchLevels() {
      this.loading = true;
      this.levels = await DangerLevelsApi.getList();
      // Placeholder for ADD NEW row
      this.levels.push({
        id: 'new',
        manufacturer_name: ' ',
        material_property_name: ' ',
        length_name: ' ',
        color_name: ' ',
        finishing_name: ' ',
        uom_name: ' ',
      });
      this.loading = false;
    },
    async fetchItems() {
      this.items = await InventoryItemsApi.getList();
    },
    async fetchWarehouses() {
      this.warehouses = await WarehousesApi.getList();
    },
    getItemText(val){
      return `${val.material_id ? val.material_id + ': ' : '' }${val.name}`;
    }
  },
};
</script>
<style scoped>
.mrp-edit-input-margin {
  margin-top: 23px !important;
  margin-bottom: 1px !important;
}
</style>
