var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"900px","persistent":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-alert',{attrs:{"type":"error"},model:{value:(_vm.errorAlert),callback:function ($$v) {_vm.errorAlert=$$v},expression:"errorAlert"}},[_vm._v(" "+_vm._s(_vm.errorAlertText)+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('p',[_vm._v("Sheet Date")]),_c('v-date-picker',{attrs:{"full-width":""},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Job is required'; }],"required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}]),model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}},[_c('template',{slot:"label"},[_vm._v(" Job"),_c('RedAsterisk')],1)],2),_c('v-text-field',{attrs:{"label":"Release/Floor"},model:{value:(_vm.release_floor),callback:function ($$v) {_vm.release_floor=$$v},expression:"release_floor"}}),_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.ex_color),callback:function ($$v) {_vm.ex_color=$$v},expression:"ex_color"}},[_c('template',{slot:"label"},[_vm._v(" External colour ")])],2),_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","required":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"black--text"},[_vm._v(" "+_vm._s(item.code)+": "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.in_color),callback:function ($$v) {_vm.in_color=$$v},expression:"in_color"}},[_c('template',{slot:"label"},[_vm._v(" Internal colour ")])],2)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.btnSubmitName)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }