var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"1500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogTitle)+" "),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"movements-table","headers":_vm.profilesHeaders,"items":_vm.profiles,"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('a',{on:{"click":function($event){return _vm.openItemShowImageForm(item.photo, item.mime_type, item.material_id)}}},[_c('v-img',{staticClass:"profile-photo",attrs:{"src":_vm.convertedPhoto(item.photo, item.mime_type)}})],1):_vm._e()]}},{key:"item.die",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.material_id)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.color ?item.color.code ? item.color.code : item.color.name ? item.color.name : '' : '')+" ")]}},{key:"item.profile",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile)+" ")]}},{key:"item.quantity_supplied",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.quantity_supplied),callback:function ($$v) {_vm.$set(item, "quantity_supplied", $$v)},expression:"item.quantity_supplied"}},[_vm._v(_vm._s(item.quantity_supplied))])]}},(_vm.isReturn == 0)?{key:"item.quantity_missed",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.quantity_missed),callback:function ($$v) {_vm.$set(item, "quantity_missed", $$v)},expression:"item.quantity_missed"}})]}}:null,{key:"item.inventory_tag",fn:function(ref){
var item = ref.item;
return [_c('v-combobox',{ref:"form",attrs:{"items":_vm.computedInventoryTags(item),"item-text":"inventory_tag","text-val":"item","return-object":false,"rules":[function (v) { return !!v || 'Inventory tag is required'; }],"required":""},model:{value:(item.inventory_tag),callback:function ($$v) {_vm.$set(item, "inventory_tag", $$v)},expression:"item.inventory_tag"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.submitForm(item)}}},[_vm._v(" "+_vm._s(_vm.btnSubmitName)+" ")])]}}],null,true)})],1)],1)],1),_c('InventoryItemShowImageForm',{ref:"itemShowImageForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }