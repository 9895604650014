<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="9" class="d-flex justify-end">
        <v-btn
          color="primary"
          outlined
          class="d-flex align-self-end"
          @click="
            () => {
              this.$refs.consumptionForm.openDialog();
            }
          "
        >
          <v-icon small class="mr-1">fas fa-plus</v-icon>
            New Supply to Line / Return Sheet
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-1"
          :headers="consumptionsHeaders"
          :items="consumptions"
          :footer-props="itemsTableFooterProps"
          :items-per-page="20"
          :search="search"
          :loading="loading"
          dense
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <a @click="openConsumption(item)">
                <TextHighlight :queries="search">
                  {{ item.id }}
                </TextHighlight>
                </a>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.sheet_date_formated }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{item.job_name}}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.address_simple }}
                </TextHighlight>
              </td>
              <!-- <td>
                <TextHighlight :queries="search">
                  {{ item.supervisor }}
                </TextHighlight>
              </td> -->
              <td>
                <TextHighlight :queries="search">
                  {{ item.created_at }} by {{item.created_by}}
                </TextHighlight>
              </td>
             
              <td>
                <TextHighlight :queries="search">
                  {{ item.updated_at }} by {{item.updated_by}}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.release_floor }}
                </TextHighlight>
              </td>
              <td>
                <TextHighlight :queries="search">
                  {{ item.records_number }}
                </TextHighlight>
              </td>
              <td align="end">
                <a @click="openConsumption(item)" class="mr-2">OPEN</a>
                <a @click="openConsumptionForm(item)" class="mr-2"> EDIT </a>
                <a @click="deleteConsumption(item)" class="mr-2">DELETE</a>
                
                
              </td>
            </tr>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            {{ item.address ? item.address : '-' }}
          </template>
          <template v-slot:[`item.supervisor`]="{ item }">
            {{ item.supervisor ? item.supervisor : '-' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <a @click="openConsumption(item)" v-if="canEdit" class="mr-2"> EDIT </a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar"></Snackbar>
    <ConsumptionForm
      ref="consumptionForm"
      v-on:consumption-creation-success="notifyConsumptionCreationSuccess"
      v-on:consumption-creation-fail="notifyConsumptionCreationFail"
    ></ConsumptionForm>
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import Snackbar from '../../components/Snackbar';
import ConsumptionForm from '../../components/forms/ConsumptionForm';
import ConsumptionsApi from '../../api/ConsumptionsApi';

export default {
  name: 'Consumptions',
  components: {
    TextHighlight,
    Snackbar,
    ConsumptionForm,
  },
  data: () => ({
    search: '',
    loading: true,
    colors: [],
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    consumptionsHeaders: [
      { text: 'Sheet #', value: 'id', width: '7%' },
      { text: 'Sheet Date', value: 'sheet_date',  width: '10%' },
      { text: 'Job Name', value: 'job_name'},
      { text: 'Job Address', value: 'address_simple',  width: '10%' },
      { text: 'Created At', value: 'created_at',  width: '15%' },
      { text: 'Updated At', value: 'updated_at',  width: '15%' },
      { text: 'Release/Floor', value: 'release_location' },
      { text: 'Records#', value: 'records_number' },
      { text: 'Actions', value: 'actions', width: '15%', align: 'end' },
    ],
    consumptions: [],
    canEdit: false,
    userId: null,
  }),
  filters: {},
  created() {
    this.initialize();
    this.canEdit = this.$store.getters.userCanEdit;
    
  },
  methods: {
    async initialize() {
      this.userId = this.$store.getters.user.id;
      Promise.all([this.fetchConsumptions()]);
      
    },
    openConsumptionForm(item) {
      this.$refs.consumptionForm.openDialog(item);
    },
    showBoms(item, type) {
      this.$emit('show-boms', item.id, type);
    },
    notifyConsumptionCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.fetchConsumptions();
    },
    notifyConsumptionCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    async fetchConsumptions() {
      this.loading = true;
      if(this.userId != null) this.consumptions = await ConsumptionsApi.getConsumptionsByRole(this.userId);
      else this.consumptions = [];
      this.loading = false;
    },
    openConsumption(item) {
      this.$router.push(`/consumption/edit-sheet/${item.id}`);
    },
    async deleteConsumption(item){
       let confirmation = confirm('Are you sure that you want to delete this consumption?')
      if(confirmation) {
        const res = await ConsumptionsApi.delete(item.id);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.fetchConsumptions();
      }  
      
    }
  },
};
</script>
<style scoped></style>
